import React, { useEffect, useState } from "react";
import ClubSidebar from "../../components/ClubSidebar";
import { CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import Cookies from "universal-cookie";
function BankData() {
  const cookies = new Cookies();
  const [data, setData] = useState();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAccountName, setBankAccountName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  useEffect(() => {
    async function fetchClubBankData() {
      try {
        const token = cookies.get("_auth_token");
        const api = "https://gymbackend-r5nw.onrender.com/club/bank_data";
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const res = await axios.get(api, config);
        const { data } = res.data;
        if (data) {
          setData(data);
          setName(data.name || "");
          setPhone(data.phone || "");
          setBankName(data.bankName || "");
          setBankAccountName(data.bankAccountName || "");
          setBankAccountNumber(data.bankAccountNumber || "");
        }
      } catch (error) {
        console.error("Error fetching club bank data:", error);
      }
    }

    fetchClubBankData();
  }, []);
  async function sendBankAccount() {
    console.log(cookies.get("_auth_token"));

    const api = "https://gymbackend-r5nw.onrender.com/club/bank_data";
    const token = cookies.get("_auth_token"); // Assuming you're using some library for cookies
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const res = await axios.post(
        api,
        {
          name: name,
          phone: phone,
          bankName: bankName,
          bankAccountName: bankAccountName,
          bankAccountNumber: bankAccountNumber,
        },
        config
      );
      console.log(res);
      window.location.reload();
    } catch (error) {
      console.error("Error sending bank account:", error);
    }
  }

  function handleClick(e) {
    e.preventDefault();
    if (name && phone && bankName && bankAccountName && bankAccountNumber) {
      console.log(name, phone, bankName, bankAccountName, bankAccountNumber);
      sendBankAccount();
    }
  }
  if (!data) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className="flex justify-center">
      <div className="flex-1">
        <div className="text-2xl text-center  bg-neutral-700 px-5 py-3  text-white  w-full ">
          {" "}
          الحساب البنكي
        </div>

        <form
          onSubmit={handleClick}
          className="p-5 w-fill sm:w-[450px] mx-auto  "
        >
          <h2 className="text-3xl my-3 font-light text-right text-slate-700">
            البيانات الشخصيه
          </h2>
          <TextField
            required
            onChange={(e) => setName(e.target.value)}
            sx={{ width: "100%" }}
            id="outlined-basic"
            value={name}
            label="الاسم"
            variant="outlined"
            type="text"
          />
          <div className="my-5"></div>
          <TextField
            required
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            sx={{ width: "100%" }}
            id="outlined-basic"
            label="رقم الهاتف"
            variant="outlined"
            type="phone"
          />
          <h2 className="text-3xl my-3 font-light text-right text-slate-700">
            {" "}
            بيانات الحساب البنكي
          </h2>
          <TextField
            required
            onChange={(e) => setBankName(e.target.value)}
            sx={{ width: "100%" }}
            id="outlined-basic"
            label="اسم البنك  "
            value={bankName}
            variant="outlined"
            type="text"
          />
          <div className="my-5"></div>

          <TextField
            required
            value={bankAccountName}
            onChange={(e) => setBankAccountName(e.target.value)}
            sx={{ width: "100%" }}
            id="outlined-basic"
            label="اسم صاحب الحساب  "
            variant="outlined"
            type="text"
          />
          <div className="my-5"></div>
          <TextField
            required
            value={bankAccountNumber}
            onChange={(e) => setBankAccountNumber(e.target.value)}
            sx={{ width: "100%" }}
            id="outlined-basic"
            label="رقم الحساب"
            variant="outlined"
            type="text"
          />
          <div className="my-5"></div>

          <button
            type="submit"
            className=" w-full  rounded-lg p-2 outline-none border-none bg-teal-500 text-white text-3xl"
          >
            {" "}
            حفظ
          </button>
        </form>
      </div>
      <ClubSidebar />
    </div>
  );
}

export default BankData;

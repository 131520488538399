import React, { useEffect, useRef, useState, useMemo } from "react";
import Sidebar from "../../components/AdminSidebar";
import "mapbox-gl/dist/mapbox-gl.css";
import { AiOutlineClose } from "react-icons/ai";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GetClubHandler } from "./../../apis/user/GetClub";
import { Backdrop, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import Cookies from "universal-cookie";
import { EditPersonalClubHandler } from "../../apis/clubs/EditPersonalClub";
import ClubSidebar from "../../components/ClubSidebar";
import YogaCard from "../../components/club/YogaCard";
const EditClub = () => {
  const cookies = new Cookies();

  const id = cookies.get("_auth_club");

  const [cuurentLocation, setCurrentLocation] = useState({ lat: 0, long: 0 });
  const [selection, setSelection] = useState({ lat: null, long: null });
  const [imgs, setImgs] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [gender, setGender] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [yogaCardData, setYogaCardData] = useState([]);
  const [isWork, setIsWork] = useState(true);
  const [daysOf, setDaysOf] = useState("");
  const [isDaysOf, setIsDaysOf] = useState(false);
  // Function to update the yoga card data

  const [commission, setCommission] = useState("");
  const [logo, setLogo] = useState();
  const [clubImage, setClubImage] = useState([]);
  const [clubData, setClubData] = useState("");
  const [error, setError] = useState("");
  const [club_loc_url, setClub_loc_url] = useState("");
  const [dataActivites, setDataActivites] = useState([]);
  const [checkedItemsSports, setCheckedItemsSports] = useState([]);
  const [checkedDays, setCheckedDays] = useState([]);
  const [DiscountCode, setCode] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  const [discountQuenttiy, setDiscountQuantity] = useState("");
  const handleBoxDays = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setCheckedDays([...checkedDays, value]);
    } else {
      setCheckedDays(checkedDays.filter((day) => day !== value));
    }
  };
  const handleCheckboxChangeSports = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setCheckedItemsSports([...checkedItemsSports, value]);
    } else {
      setCheckedItemsSports(
        checkedItemsSports.filter((item) => item !== value)
      );
    }
  };

  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const imgfiles = useRef();
  const state = useSelector((state) => state.GetClub);
  const edit = useSelector((state) => state.EditClub);
  const dispatch = useDispatch();
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  async function GetAllActivities() {
    const api = "https://gymbackend-r5nw.onrender.com/user/activities";

    try {
      const response = await axios.get(
        api,

        {
          headers: { authorization: `Bearer ${cookies.get("_auth_token")}` },
        }
      );
      console.log(response.data);
      setDataActivites(response.data.activities);
    } catch (err) {
      setError("activity already exists");
    }
  }
  useEffect(() => {
    GetAllActivities();
  }, []);
  const handleClubLocUrlChange = (e) => {
    const url = e.target.value;
    setClub_loc_url(url); // Update the input value in state

    // Regular expression pattern to match latitude and longitude values in Google Maps URL
    const pattern = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
    const match = url.match(pattern);
    console.log(`lat before: ${selection.lat}`);
    // console.log(`lat before: ${typeof(selection.lat)}`);
    console.log(`long before: ${selection.long}`);
    if (match) {
      const tmp1 = parseFloat(match[1]);
      const tmp2 = parseFloat(match[2]);
      const precision = 1000000000000000; // 15 digits after decimal point
      const lat = Math.round(tmp1 * precision) / precision;
      const lon = Math.round(tmp2 * precision) / precision;
      console.log("Latitude:", typeof lat);
      console.log("Longitude:", lon);
      setCurrentLocation({ lat, long: lon }); // Update cuurentLocation state
      setSelection({ lat, long: lon }); // Update selection state
    } else {
      console.log("Invalid URL or no latitude/longitude found.");
      setCurrentLocation({ lat: 0, long: 0 }); // Reset cuurentLocation state to default
      setSelection({ lat: null, long: null }); // Reset selection state to default
    }
    console.log(`lat after: ${selection.lat}`);
    console.log(`long after: ${selection.long}`);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    if (name) formData.append("name", name);
    if (description) formData.append("description", description);
    if (gender) formData.append("gender", gender);
    formData.append("lat", selection.lat);
    formData.append("long", selection.long);
    formData.append("from", from);
    formData.append("to", to);
    formData.append("allDay", isChecked);
    formData.append("discountCode", discountCode);
    formData.append("daysOf", daysOf);
    formData.append("discountQuantity", discountQuenttiy);
    formData.append("isWork", isWork);
    if (club_loc_url) formData.append("mapUrl", club_loc_url);
    if (yogaCardData)
      formData.append("yogaCardData", JSON.stringify(yogaCardData));

    if (checkedItemsSports.length > 0)
      formData.append("checkedItemsSports", checkedItemsSports);
    if (checkedDays.length > 0) formData.append("checkedDays", checkedDays);
    {
      clubImage && clubImage.forEach((img) => formData.append("clubImg", img));
    }
    formData.append("logo", logo);
    dispatch(EditPersonalClubHandler(formData)).then((res) => {
      if (res.payload.status === 200) {
        window.location.reload();
      }
    });
  };

  const handleImgChange = (imgs) => {
    let fileNames = [];
    for (let i = 0; i < imgs.length; i++) {
      const file = imgs[i];
      fileNames.push(file.name);
    }
    setImgs(fileNames);
  };

  const handleLogoChange = (event) => {
    const files = Array.from(event.target.files);
    setLogo(files[0]);
  };

  const handleClubImages = (event) => {
    const files = Array.from(event.target.files);
    const updatedFiles = files.map((file, index) => ({
      key: index++,
      data: file,
    }));
    const eachFile = updatedFiles.map((file) => file.data);
    setClubImage(eachFile);
  };

  useEffect(() => {
    dispatch(GetClubHandler({ id })).then((res) => {
      if (res.payload.data) {
        setClubData(res.payload.data.club);
      }
    });
  }, [dispatch, id]);

  useEffect(() => {
    if (clubData) {
      setName(clubData.name);
      setIsChecked(clubData.allDay);
      setFrom(clubData.from);
      setTo(clubData.to);
      setDescription(clubData.description);
      setGender(clubData.gender);
      setCommission(clubData.commission);
      setLogo([...clubData.logo]);
      setCheckedDays(clubData.WorkingDays);
      setImgs(clubData.images);
      setCheckedItemsSports(clubData.sports);
      setClub_loc_url(clubData.mapUrl);
      setIsWork(clubData.isWork);
      localStorage.setItem("isAddClubs", clubData.isAddClubs);
    }
    console.log(clubData);
  }, [clubData]);

  useEffect(() => {
    const pattern = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
    const match = club_loc_url.match(pattern);
    console.log(`lat before: ${selection.lat}`);
    // console.log(`lat before: ${typeof(selection.lat)}`);
    console.log(`long before: ${selection.long}`);
    if (match) {
      const tmp1 = parseFloat(match[1]);
      const tmp2 = parseFloat(match[2]);
      const precision = 1000000000000000; // 15 digits after decimal point
      const lat = Math.round(tmp1 * precision) / precision;
      const lon = Math.round(tmp2 * precision) / precision;
      console.log("Latitude:", typeof lat);
      console.log("Longitude:", lon);
      setCurrentLocation({ lat, long: lon }); // Update cuurentLocation state
      setSelection({ lat, long: lon }); // Update selection state
    } else {
      console.log("Invalid URL or no latitude/longitude found.");
      setCurrentLocation({ lat: 0, long: 0 }); // Reset cuurentLocation state to default
      setSelection({ lat: null, long: null }); // Reset selection state to default
    }
    console.log(`lat after: ${selection.lat}`);
    console.log(`long after: ${selection.long}`);
  }, [club_loc_url]);

  // useEffect(() => {
  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const { latitude, longitude } = position.coords;
  //         setCurrentLocation({ lat: latitude, long: longitude });
  //       },
  //       (error) => {
  //         console.error("Error getting location:", error);
  //       }
  //     );
  //   } else {
  //     console.error("Geolocation is not supported by your browser");
  //   }
  // }, []);

  const handleMapClick = (e) => {
    const { latLng } = e;
    const lat = latLng.lat();
    const long = latLng.lng();
    setSelection({ lat, long });
  };
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCewVD8Afv0cy6NGoCZkQ4PZRW3OQCFfHA",
  });
  const center = useMemo(
    () => ({
      lat: cuurentLocation.lat ? cuurentLocation.lat : 24.713552,
      lng: cuurentLocation.long ? cuurentLocation.long : 46.675297,
    }),
    [cuurentLocation]
  );

  const updateYogaCardData = (newData) => {
    setYogaCardData(newData);
  };

  if (!clubData) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }
  console.log(setCheckedItemsSports);
  return (
    <div className="flex min:h-screen ">
      {state.loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            name: clubData.name,
            gender: clubData.gender,
            days: clubData.days,
            description: clubData.description,
            from: clubData.from,
            to: clubData.to,
            commission: clubData.commission,
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              className="flex-1 flex flex-col bg-stone-100 items-end gap-y-5 rounded-lg w-screen"
            >
              <span className="text-2xl text-center  bg-neutral-700 px-5 py-3  text-white  w-full ">
                تعديل نادي
              </span>
              <div className="flex flex-col w-3/5   px-5">
                <span className="text-2xl text-right">تعديل نادي</span>
                <span className="text-md text-right text-gray-500">
                  اكتب بيانات النادي حتي تتم تعديلها
                </span>
                <div className="flex justify-end gap-x-3 flex-wrap-reverse gap-y-2">
                  <select
                    name="gender"
                    value={values.gender}
                    onChange={handleChange}
                    onChangeCapture={(e) => setGender(e.target.value)}
                    type="text"
                    className="border-2 text-right border-black  px-3 py-1 text-xl"
                  >
                    <option value={"male"}>ذكور</option>
                    <option value={"female"}>اناث</option>
                    <option value={"both"}>مشترك</option>
                  </select>

                  <input
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onChangeCapture={(e) => setName(e.target.value)}
                    type="text"
                    className="border-2 text-right border-black sm:w-1/3 px-3 py-1 text-xl rounded-md"
                  />
                </div>
              </div>
              <div className="flex sm:flex-row flex-col-reverse sm:gap-x-5  px-5">
                <div className="flex flex-col justify-center items-end">
                  <span className="text-xl text-right">
                    مواعيد العمل يوم الجمعه
                  </span>
                  <div className="flex justify-center flex-wrap gap-5">
                    <div className="flex flex-col-reverse   sm:flex-row justify-center flex-wrap gap-5">
                      <div>
                        <input
                          name="to"
                          value={values.to}
                          onChange={handleChange}
                          onChangeCapture={(e) => setTo(e.target.value)}
                          type="time"
                        />
                        <span className="text-lg ml-1 ">الي</span>
                      </div>
                      <div>
                        <input
                          name="from"
                          value={values.from}
                          onChange={handleChange}
                          onChangeCapture={(e) => setFrom(e.target.value)}
                          type="time"
                        />
                        <span className="text-lg ml-1">من</span>
                      </div>
                    </div>
                    <div>
                      <label htmlFor="checkbox" className="flex items-center">
                        <input
                          type="checkbox"
                          name="allDay"
                          id="checkbox"
                          className="mr-2"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        <span>24ساعة</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col my-2">
                  <span className="text-xl text-right">لوجو النادي</span>
                  <input
                    onChange={handleLogoChange}
                    type="file"
                    className=" text-sm w-full text-white bg-neutral-700 border-2 border-black rounded-md p-2"
                  />
                  {clubData.logo && (
                    <div className=" m-6">
                      <img
                        src={clubData.logo}
                        alt=""
                        className=" w-12 h-12 rounded-md "
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="p-4 ">
                <h3 className="text-center text-2xl text-slate-800 mb-4 ">
                  اوقات العمل خلال الاسبوع
                </h3>
                <div className="  flex-wrap flex gap-3 items-center justify-center">
                  {weekdays.map((day, idx) => {
                    return (
                      <div
                        key={idx}
                        className=" bg-indigo-800 p-2 gap-2 flex items-center  justify-between rounded-md cursor-pointer"
                      >
                        <h3 className="text-sm text-slate-100 ">{day}</h3>
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          value={day}
                          onChange={handleBoxDays}
                          checked={checkedDays.includes(day)}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="p-4 ">
                <h3 className="text-center text-2xl font-medium block m-4 p-4 ">
                  {" "}
                  الانشطه الرياضيه{" "}
                </h3>

                {dataActivites?.length === 0 ? (
                  <span className=" block text-center  text-slate-700 text-xl">
                    لا يوجد اي انشطه مضافه
                  </span>
                ) : (
                  <div className="flex gap-7 flex-wrap items-center justify-center">
                    {dataActivites?.map((activity, idx) => {
                      return (
                        <div
                          key={idx}
                          className="bg-gray-200 p-2 sm:p-4 flex items-center gap-7 justify-between rounded-md cursor-pointer"
                        >
                          <h3 className="text-lg sm:text-2xl text-slate-800 ">
                            {activity.sportName}
                          </h3>
                          <input
                            id="default-checkbox"
                            type="checkbox"
                            value={activity.sportName}
                            onChange={handleCheckboxChangeSports}
                            checked={checkedItemsSports.includes(
                              activity.sportName
                            )}
                            className=" w-5 h-5 sm:w-7 sm:h-7 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="flex flex-col w-full  px-5">
                <span className="text-xl text-right">عن النادي</span>
                <span className="text-md text-right text-gray-500">
                  أدخل وصف النادي
                </span>
                <textarea
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  placeholder={clubData.description}
                  onChangeCapture={(e) => setDescription(e.target.value)}
                  className="w-full  resize-none border-2 border-black min-h-52 text-right"
                ></textarea>
              </div>

              <div className="flex flex-col w-full  px-5">
                <span className="text-xl text-right">صور النادي</span>
                {/* if no Imgs*/}
                {/* <span>قم باضافه صور النادي</span> */}
                {/*if imgs*/}
                <div className="flex flex-col  gap-x-3 justify-center items-center ">
                  <input
                    type="file"
                    multiple
                    className=" text-sm w/fit text-white bg-neutral-700 border-2 border-black rounded-md p-2"
                    ref={imgfiles}
                    onChange={(e) => {
                      handleImgChange(e.target.files);
                      handleClubImages(e);
                    }}
                  />
                  <div className="flex gap-6 flex-col justify-center  items-center my-3">
                    {imgs.length ? (
                      imgs.map((name) => {
                        return (
                          <div className="flex  gap-6 items-center w-full justify-evenly gap-x-5">
                            <AiOutlineClose
                              className="text-red-500"
                              onClick={() =>
                                setImgs(
                                  imgs.filter((filename) => filename !== name)
                                )
                              }
                            />
                            <img
                              src={name}
                              alt=""
                              className=" w-16  h-16 rounded-md "
                            />
                          </div>
                        );
                      })
                    ) : (
                      <span className="text-xl text-red-500">
                        فم باضافه صور النادي
                      </span>
                    )}
                  </div>
                </div>

                {checkedItemsSports &&
                  checkedItemsSports.length === 1 &&
                  checkedItemsSports[0].trim() === "يوغا" && (
                    <YogaCard
                      updateYogaCardData={updateYogaCardData}
                      weekDays={[...checkedDays]}
                      club={clubData.yogaSessions || []}
                    />
                  )}

                <div className="flex gap-4 flex-row-reverse p-3 flex-wrap m-3  ">
                  <button
                    onClick={() => {
                      setCode(!DiscountCode);
                      console.log(DiscountCode);
                    }}
                    className={`p-2  rounded-2xl ${
                      DiscountCode && "bg-red-300 text-gray-700"
                    } text-gray-600  capitalize bg-teal-500`}
                  >
                    اضافه كود خصم
                  </button>
                  {DiscountCode && (
                    <div
                      style={{
                        transition: "all .05s ease-in-out",
                      }}
                      className="flex items-center justify-center flex-wrap gap-7"
                    >
                      <TextField
                        onChange={(e) => setDiscountCode(e.target.value)}
                        id="outlined-number"
                        label="كود الخصم"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        onChange={(e) => setDiscountQuantity(e.target.value)}
                        id="outlined-number"
                        label="قيمه الخصم"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="flex flex-col w-full  px-5">
                  <span className="text-xl text-right">رابط عنوان النادي </span>
                  <span className="text-md text-right text-gray-500">
                    اختياري: يمكنك ادخال رابط عنوان النادي من خرائط جوجل او
                    التحديد من الخريطة
                  </span>
                  <textarea
                    name="club_loc_url"
                    value={club_loc_url}
                    onChange={handleClubLocUrlChange}
                    className="w-full  resize-none border-2 border-black min-h-52 text-right"
                  ></textarea>
                </div>

                <div className="w-full justify-center items-end flex flex-col  px-5">
                  <span className="text-xl">عنوان النادي</span>
                  {/* Map */}

                  <div id="map" className="h-screen w-full relative map">
                    {!isLoaded ? (
                      <h1>Loading...</h1>
                    ) : (
                      <GoogleMap
                        mapContainerClassName="map-container"
                        center={center}
                        zoom={10}
                        onClick={(e) => handleMapClick(e)}
                      >
                        <Marker
                          position={
                            selection.lat
                              ? {
                                  lat: selection.lat,
                                  lng: selection.long,
                                }
                              : {
                                  lat: cuurentLocation.lat
                                    ? cuurentLocation.lat
                                    : 24.713552,
                                  lng: cuurentLocation.long
                                    ? cuurentLocation.long
                                    : 46.675297,
                                }
                          }
                          icon={"/assets/placeholder_google_maps1.jpg"}
                        />
                      </GoogleMap>
                    )}
                  </div>
                </div>
                <div className=" p-3 flex flex-col flex-wrap rounded-md   justify-center items-center ">
                  <div className=" p-3   flex  bg-slate-600  flex-wrap w-[70vw] sm:w-[30vw]   gap-2 sm:gap-6  justify-center items-center  ">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      onChange={(e) => {
                        setIsWork(e.target.checked);
                      }}
                      checked={isWork}
                      className=" w-5 h-5 sm:w-7 sm:h-7 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <h3 className="  text-white text-center text-xl sm:text-2xl  font-semibold">
                      النادي في حاله عمل الان
                    </h3>
                  </div>
                  <div className=" mt-6   flex-wrap flex gap-3 items-center justify-center">
                    <div>
                      {isDaysOf && (
                        <label
                          htmlFor="ايام الايقاف"
                          className="relative block overflow-hidden rounded-md border border-gray-200 px-3 pt-3 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600"
                        >
                          <input
                            value={daysOf}
                            onChange={(e) => setDaysOf(e.target.value)}
                            type="number"
                            id="daysOf"
                            placeholder="Days Of"
                            className="peer h-8 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                          />

                          <span className="absolute start-3 top-3 -translate-y-1/2 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-3 peer-focus:text-xs">
                            ايام الايقاف
                          </span>
                        </label>
                      )}
                    </div>
                    <h1 className="text-white bg-teal-700 cursor-pointer  p-3 rounded-md" type="click" onClick={() => setIsDaysOf(!isDaysOf)}>
                      جدوله
                    </h1>
                  </div>
                </div>

                <div className="flex w-full items-center justify-center ">
                  <button
                    type="submit"
                    className={
                      "text-white bg-green-600 text-2xl my-5 px-5 py-2 w-1/4 hover:scale-125 transition-all  rounded-lg "
                    }
                  >
                    {edit.loading ? (
                      <CircularProgress size={30} sx={{ color: "white" }} />
                    ) : (
                      "تعديل"
                    )}
                  </button>
                </div>
                <div className="text-center text-red-500 font-bold">
                  {error}
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
      <ClubSidebar />
    </div>
  );
};

export default EditClub;

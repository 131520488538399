import React, { useEffect, useState } from "react";
import ClubSidebar from "../../components/ClubSidebar";
import { BiBlock } from "react-icons/bi";
import Cookies from "universal-cookie";
import { GetClubHandler } from "./../../apis/user/GetClub";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import PaidIcon from "@mui/icons-material/Paid";
import { AddSubHandler } from "./../../apis/clubs/AddSubscription";
import { DeleteSubHandler } from "./../../apis/clubs/DeletSubscription";
import { CircularProgress, TextField } from "@mui/material";

const AddSubscribes = () => {
  const [isBadle, setIsBadle] = useState(false);
  const [isAnotherAcitivity, setIsAnotherActivity] = useState(false);
  const [isYoga, setIsYoga] = useState(false);
  const [sarPrice, setSarPrice] = useState(false);
  const [dolPrice, setDolPrice] = useState(false);
  const [subs, setSubs] = useState([]);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [numberType, setNumberType] = useState("1");
  const [type, setType] = useState("سنوي");
  const [freezCount, setFreezeCount] = useState(0);
  const [freezPeriod, setFreezePeriod] = useState(0);
  const [freeze, setFreeze] = useState(false);
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [club, setClub] = useState();
  const [clubSports, setClubSports] = useState([]);
  const [gymsCount, setGymsCount] = useState("0");
  useEffect(() => {
    if (type == "يومي" || type == "اسبوعي" || type == "ساعه") {
      console.log("يومي اسبوعي");
      setFreeze(false);
    } else {
      setFreeze(true);
    }
  }, [type]);
  const handleForm = (e) => {
    setError("");

    if (price && price && type) {
      if (
        type === "يوم واحد" ||
        type === "يومين" ||
        type === "ثلاث ايام" ||
        type === "اربع ايام" ||
        type === "خمس ايام" ||
        type === "ست ايام"
      ) {
        if (type === "يوم واحد") {
          setNumberType("1");
          setType("يومي");
        } else if (type === "يومين") {
          setNumberType("2");
          setType("يومي");
        } else if (type === "ثلاث ايام") {
          setNumberType("3");
          setType("يومي");
        } else if (type === "اربع ايام") {
          setNumberType("4");
          setType("يومي");
        } else if (type === "خمس ايام") {
          setNumberType("5");
          setType("يومي");
        } else if (type === "ست ايام") {
          setNumberType("6");
          setType("يومي");
        }
      
      }
      dispatch(
        AddSubHandler({
          name,
          price,
          type,
          numberType,
          freezPeriod,
          freezCount,
          gymsCount,
        })
      ).then((res) => {
        if (res.payload.status === 201) {
          setError("تمت الاضافه بنجاح");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else if (res.payload.status === 403) {
          setError("يوجد اشتراك بهذا النوع");
        } else {
          setError("يوجد خطأ");
        }
      });
      console.log(
        name,
        price,
        type,
        numberType,
        freezPeriod,
        freezCount,
        gymsCount
      );
    }
  };

  const handleDelete = (id) => {
    dispatch(DeleteSubHandler({ id })).then(() => {
      dispatch(GetClubHandler({ id: cookies.get("_auth_club") })).then(
        (res) => {
          if (res.payload.data) {
            setSubs(res.payload.data.subscriptions);
          }
        }
      );
    });
  };

  useEffect(() => {
    setPrice(Math.round(price));
    console.log(price);
  }, [price]);
  useEffect(() => {
    dispatch(GetClubHandler({ id: cookies.get("_auth_club") })).then((res) => {
      if (res.payload.data) {
        setClub(res.payload.data);
        setClubSports(res.payload.data.club.sports);
        if (
          res.payload.data.club.sports.length === 1 &&
          res.payload.data.club.sports[0].trim() === "بادل"
        ) {
          setIsBadle(true);
          console.log("isBadel");
          setType("60Minutes");
        } else if (
          res.payload.data.club.sports.length === 1 &&
          res.payload.data.club.sports[0].trim() === "الأنشطة الأخرى"
        ) {
          setIsAnotherActivity(true);
          console.log("isAnotherActivity");
          setType("30Minutes");
        } else if (
          res.payload.data.club.sports.length === 1 &&
          res.payload.data.club.sports[0].trim() === "يوغا"
        ) {
          setIsYoga(true);
          console.log("isYoga");
          setType("يوم واحد");
        }

        setSubs(res.payload.data.subscriptions);
      }
    });
  }, [dispatch]);

  if (club == null) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }
  console.log("##############");
  console.log(club);
  console.log(clubSports);
  console.log(isBadle);

  return (
    <div className="flex justify-center">
      <div className="flex md:flex-1 flex-col-reverse md:flex-row h-screen  justify-evenly items-center bg-slate-100 m-4 rounded-xl">
        <div className=" md:flex-1 flex flex-col items-end w-full overflow-y-scroll max-h-[60vh] scrollbar-track p-5 gap-y-8">
          <span className="text-2xl">الباقات المضافه</span>
          <div className="flex w-full justify-around gap-5 flex-wrap items-center">
            {subs &&
              (subs.length !== 0 ? (
                subs.map((sub) => (
                  <div
                    style={{
                      backgroundImage: "url('/assets/main-img-blured.jpg')",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                    className="relative w-1/4 flex flex-col items-center justify-center rounded-lg p-5"
                  >
                    <BiBlock
                      onClick={() => handleDelete(sub._id)}
                      className="text-white bg-red-700 border-black borderr-2 rounded-full text-xl absolute left-0 bottom-0 rotate-90 cursor-pointer hover:scale-125 transition-all"
                    />
                    <span className="text-white text-2xl">{sub.type}</span>
                    <span className="text-white text-xl">{sub.price} Sar</span>
                  </div>
                ))
              ) : (
                <div className="flex text-center justify-center text-red-500 font-bold">
                  لا يوجد اشتراكات مضافه
                </div>
              ))}
          </div>
        </div>

        <div className=" flex-col flex w-full items-end p-5 gap-y-3 md:flex-1 md:border-l-2 md:border-neutral-700">
          <span className="text-2xl md:text-3xl">اضف باقه اشتراك</span>
          <span className="text-lg  text-gray-500">
            اضف بيانات باقه الاشتراك
          </span>
          <input
            name="name"
            onChange={(e) => setName(e.target.value)}
            type="text"
            placeholder="اسم الباقه"
            className="  text-xl text-right rounded-lg border-2 border-black px-3 py-1 w-full md:w-1/3 md:text-2xl "
          />
          <div>
            <p className="  text-slate-700 text-2xl text-center p-3">
              عمله الدفع
            </p>
            <div
              className={`flex  items-center justify-center gap-3 flex-wrap`}
            >
              <button
                type="button"
                onClick={() => {
                  setDolPrice(true);
                  setSarPrice(false);
                }}
                className={` flex  gap-3 items-center  bg-gray-800 p-3 text-lg rounded-2xl  text-white font-semibold hover:bg-red-700 ${
                  dolPrice ? "bg-pink-700" : ""
                }  `}
              >
                <PaidIcon
                  sx={{
                    fontSize: "30px",
                  }}
                />
                <h2>الدولار</h2>
              </button>
              <button
                onClick={() => {
                  setDolPrice(false);
                  setSarPrice(true);
                }}
                className={` flex  gap-3 items-center  bg-gray-800 p-3 text-lg rounded-2xl  text-white font-semibold hover:bg-red-700 ${
                  sarPrice ? "bg-pink-700" : ""
                }  `}
              >
                <PaidIcon
                  sx={{
                    fontSize: "30px",
                  }}
                />
                <h2>الريال</h2>
              </button>
            </div>
          </div>
          {dolPrice && (
            <input
              name="price"
              onChange={(e) => setPrice(e.target.value * 3.75)}
              type="number"
              placeholder="رسوم الاشتراك"
              className=" transition-opacity duration-200 text-xl text-right rounded-lg border-2 border-black px-3 py-1 w-full md:w-1/3 md:text-2xl"
            />
          )}
          {sarPrice && (
            <input
              name="price"
              onChange={(e) => setPrice(e.target.value)}
              type="number"
              placeholder="رسوم الاشتراك"
              className=" transition-opacity duration-200 text-xl text-right rounded-lg border-2 border-black px-3 py-1 w-full md:w-1/3 md:text-2xl"
            />
          )}
          {isYoga && (
            <h2 className="text-2xl font-semibold ">الحجز ساعه يوميا</h2>
          )}
          {isBadle ? (
            <select
              name="type"
              onChange={(e) => setType(e.target.value)}
              className="text-xl text-right rounded-lg border-2 border-black px-3 py-1 w-full md:w-1/3 md:text-2xl"
            >
              <option>60Minutes</option>
              <option>90Minutes</option>

              <option>120Minutes</option>
            </select>
          ) : isAnotherAcitivity ? (
            <select
              name="type"
              onChange={(e) => setType(e.target.value)}
              className="text-xl text-right rounded-lg border-2 border-black px-3 py-1 w-full md:w-1/3 md:text-2xl"
            >
              <option>30Minutes</option>
              <option>60Minutes</option>
              <option>90Minutes</option>
              <option>120Minutes</option>
            </select>
          ) : isYoga ? (
            <select
              name="type"
              onChange={(e) => {
                setType(e.target.value);
              }}
              className="text-xl text-right rounded-lg border-2 border-black px-3 py-1 w-full md:w-1/3 md:text-2xl"
            >
              <option>يوم واحد</option>

              <option>يومين</option>
              <option>ثلاث ايام</option>
              <option>اربع ايام</option>
              <option>خمس ايام</option>
              <option>ست ايام</option>
            </select>
          ) : (
            <select
              name="type"
              onChange={(e) => setType(e.target.value)}
              className="text-xl text-right rounded-lg border-2 border-black px-3 py-1 w-full md:w-1/3 md:text-2xl"
            >
              <option>سنوي</option>
              <option>شهري</option>
              <option>يومي</option>
              <option>ساعه</option>
              <option>اسبوعي</option>
            </select>
          )}
          {isBadle === false &&
            isAnotherAcitivity === false &&
            isYoga === false &&
            freeze && (
              <div className=" flex gap-2 flex-wrap ">
                <div>
                  <label
                    className=" block text-sm font-bold text-gray-700"
                    htmlFor="غدد مرات تجميد الاشتراك"
                  >
                    عدد مرات تجميد الاشتراك
                  </label>
                  <input
                    onChange={(e) => setFreezeCount(e.target.value)}
                    placeholder="0"
                    className=" text-sm sm:text-xl  p-2 outline-none rounded-md  focus:border border-gray-400  "
                    type="number"
                    min={0}
                  />
                </div>
                <div>
                  <label
                    className=" block text-sm font-bold text-gray-700"
                    htmlFor="غدد مرات تجميد الاشتراك"
                  >
                    مده التجميد
                  </label>
                  <input
                    onChange={(e) => setFreezePeriod(e.target.value)}
                    placeholder="0"
                    className=" text-sm sm:text-xl  p-2 outline-none rounded-md  focus:border border-gray-400"
                    type="number"
                    min={0}
                    max="10"
                  />
                </div>
              </div>
            )}

          {isBadle === false &&
            isAnotherAcitivity === false &&
            isYoga === false && (
              <input
                name="numberType"
                onChange={(e) => setNumberType(e.target.value)}
                type="number"
                min="1"
                max="10"
                value={numberType}
                placeholder=" المده حسب النوع"
                className="text-xl text-right rounded-lg border-2 border-black px-3 py-1 w-full md:w-1/3 md:text-2xl"
              />
            )}
          {(isBadle || isAnotherAcitivity) && (
            <div className="flex items-end flex-col  w-full justify-start ">
              <h3 className=" text-xl font-semibold text-gray-800">
                عدد الصالات الرياضيه{" "}
              </h3>
              <input
                name="gymsCount"
                onChange={(e) => setGymsCount(e.target.value)}
                type="number"
                min="1"
                max="10"
                value={gymsCount}
                placeholder="عدد الصالات الرياضيه"
                className="text-xl text-right rounded-lg border-2 border-black px-3 py-1 w-full md:w-1/3 md:text-2xl"
              />
            </div>
          )}
          {name !== "" && price !== "" && type !== "" ? (
            <button
              type="submit"
              onClick={handleForm}
              className="text-xl   rounded-lg border-2 border-neutral-600 bg-neutral-700 text-center text-white px-3 py-1 w-full md:w-1/3 md:text-2xl hover:scale-125 transition-all"
            >
              اضافه
            </button>
          ) : (
            <button className="text-xl cursor-not-allowed  rounded-lg border-2 border-neutral-600 bg-neutral-700 text-center text-white px-3 py-1 w-full md:w-1/3 md:text-2xl hover:scale-125 transition-all">
              اضافه
            </button>
          )}
          <div className="text-red-500 font-bold">{error}</div>
        </div>
      </div>
      <ClubSidebar />
    </div>
  );
};

export default AddSubscribes;

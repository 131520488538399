import React, { useEffect, useState } from "react";
import Sidebar from "../../components/AdminSidebar";
import { Button, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import Cookies from "universal-cookie";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
function AddGymSections() {
  const cookies = new Cookies();
  const [sport, setSport] = useState("");
  const [sportError, setSportError] = useState("");
  const [load, setLoad] = useState(false);
  const [error, setError] = useState("");
  const [isSending ,SetIsSending] =useState(false)
  const [data ,setData] =useState([])
  async function AddActivity() {
    setLoad(true);
    setError('')
    const api = "https://gymbackend-r5nw.onrender.com/admin/add-activity";
    try {
      const response = await axios.post(
        api,
        { sportName: sport },
        {
          headers: { authorization: `Bearer ${cookies.get("_auth_token")}` },
        }
      );
      console.log(response.data);
    } catch (err) {

      setError("activity already exists",); 
    
    } finally {
      setLoad(false);
    }
  }
  async function GetAllActivities() {
  
    const api = "https://gymbackend-r5nw.onrender.com/user/activities";
    try {
      const response = await axios.get(
        api,
    
        {
          headers: { authorization: `Bearer ${cookies.get("_auth_token")}` },
        }
      );
      console.log(response);
      setData(response.data.activities)
    } catch (err) {

      setError("activity already exists",);
    } finally {
      setLoad(false);
    }
  } 
  async function deletActivity (id){
    const api = `https://gymbackend-r5nw.onrender.com/admin/delete-activity/${id}`;
    try {
      const response = await axios.delete(
        api,
        {
          headers: { authorization: `Bearer ${cookies.get("_auth_token")}` },
        }
      );
      console.log(response.data); 
      SetIsSending(!isSending)
    
    } catch (err) {
      console.log(err.message)
    } 
}

  function handleChange(e) {
    setSport(e.target.value);
  }
  function handleForm() {
    if (sport === "") {
      setSportError("Please enter sport");
    } else {
      setSportError("");

      AddActivity();
  

      SetIsSending(true)
      setSport("")
    }
    console.log(sport);
  }
  useEffect(() => {
    const fetchData = async () => {
      await GetAllActivities();
      SetIsSending(false);
    };
  
    fetchData();
  }, [isSending]);
  return (
    <div className="flex min-h-screen ">
      <div className=" w-full bg-stone-100  flex-shrink">
        <div className="w-full text-white p-3 text-2xl  bg-neutral-700  text-center ">
          اضافه انواع الرياضه
        </div>

        <div className=" my-14 w-3/4 mx-auto ">
          <div className=" flex items-center gap-3  justify-center">
            <TextField
              value={sport}
              onChange={handleChange}
              id="outlined-basic"
              label="Sport "
              variant="outlined"
            />
            <Button
              onClick={handleForm}
              sx={{
                p: "15px",
              }}
              variant="contained"
              color="success"
            >
              {load ? <CircularProgress /> : "اضافه"}
            </Button>
          </div>
          {sportError && (
            <span className=" text-center block  text-red-700 p-2">
              {sportError}
            </span>
          )}
          {error && (
            <span className=" text-center block  text-red-700 p-2">
              {error}
            </span>
          )}
          <div className=" my-10 ">
            <h2 className="text-center text-slate-700 text-3xl font-medium  ">
              جميع الانشطه الرياضيه
            </h2>
          </div>

          {
 data?.length === 0 ? <span className=" block text-center  text-slate-700 text-xl">لا يوجد اي انشطه مضافه</span> :
 <div className="flex gap-7 flex-wrap items-center justify-center">
    {
      data?.map((activity ,idx) => {
        return ( 
          <div key={idx} className="bg-gray-200 p-4 flex items-center justify-between rounded-md cursor-pointer">
            <h3 className="text-2xl text-slate-800 ">{activity.sportName}</h3>
            
            <IconButton onClick={()=>{
              deletActivity(activity._id)
            }}  aria-label="delete" style={{color:"red" ,fontSize:"30px"}}>
              <DeleteIcon fontSize="30px"/>
            </IconButton>
      
          </div>
        )
      })
    }
 </div>
}

        </div>
      </div>
      <Sidebar />
    </div>
  );
}

export default AddGymSections;

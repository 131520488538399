import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
const RepresentativeSidebar = () => { 
  const navigate = useNavigate()
  const cookies = new Cookies();
  const [activeSide, setActiveSide] = useState("");
  useEffect(() => {
    if (window.location.pathname === "/representative/add-club")
      setActiveSide("add_club");

    else if (window.location.pathname === "/representative/clubs")
      setActiveSide("clubs");
  
  }, []);
  return (
    <>
      <div className="w-fit min-h-screen max-h-full md:flex flex-col bg-neutral-700 py-10 hidden">
        <div className="flex flex-col gap-y-5 w-full justify-center items-center">
          <img src="/assets/pic.png" alt="admin img" className="w-1/3 " />
          <span className="lg:text-4xl md:text-2xl text-xl text-white">
            {cookies.get("_auth_name")}
          </span>
        </div>
        <div className="flex flex-col gap-y-10 p-10 ">
          <span
            className={`lg:text-3xl md:text-xl text-lg w-full text-right  hover:bg-neutral-400 cursor-pointer px-3 py-2 ${
              activeSide === "clubs"
                ? "bg-white text-black  transition-all "
                : "text-white"
            }`}
            onClick={() => (window.location.pathname = "/representative/clubs")}
          >
            التحكم بالنوادي
          </span>
          <span
            className={`lg:text-3xl md:text-xl text-lg  w-full text-right  hover:bg-neutral-400 cursor-pointer px-3 py-2 ${
              activeSide === "add_club"
                ? "bg-white text-black  transition-all "
                : "text-white"
            }`}
            onClick={() => (window.location.pathname = "/representative/add-club")}
          >
            اضافه نادي
          </span>
          
        </div>
      </div>
    </>
  );
};

export default RepresentativeSidebar;

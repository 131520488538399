import React, { useEffect, useState } from "react";
import Sidebar from "../../components/AdminSidebar";
import { useDispatch, useSelector } from "react-redux";
import { GetClubsReportsHandler } from "../../apis/clubs/ReportsClubs";
import { CircularProgress } from "@mui/material";
import ClubSidebar from "../../components/ClubSidebar";
import Cookies from "universal-cookie";

const ClubClupsReports = () => {
  const cookies = new Cookies();
  const id = cookies.get("_auth_club");
  const dispatch = useDispatch();
  const [data, setData] = useState();
  console.log(data);
  useEffect(() => {
    dispatch(GetClubsReportsHandler({id})).then((res) => {
      console.log(res);
      setData(res.payload.data.clubs_report);
    });
  }, [dispatch]);


  return (
    <div className="flex">
      <div className="flex-1 flex flex-col max-h-screen">
        <span className="text-2xl text-center bg-neutral-700 px-5 py-3 text-white w-full">
          تقارير النوادي
        </span>
        {!data ? (
          <div className=" flex items-center justify-center h-full">
            <CircularProgress />
          </div>
        ) : (
          <div className="w-[95%] mx-auto overflow-x-scroll flex items-center justify-center">
            <table className="bg-neutral-700 text-white mt-5 mx-5 rounded-t-lg color">
              <thead>
                <tr className=" text-center text-white">
                  <th className="border-r-2 border-b-2 border-white border-dashed py-3">
                    <th className=" block w-full py-2 border-1  border-b-2 border-white border-dashed">
                      ربح (30 minutes)
                    </th>
                    <tr>
                  ربح النادي
                    
                    </tr>
                  </th>

                  <th className="border-r-2 border-b-2 border-white border-dashed py-3">
                    <th className=" block w-full py-2 border-1  border-b-2 border-white border-dashed">
                      ربح (60 minutes)
                    </th>
                    <tr>
                    ربح النادي
                    </tr>
                  </th>
                  <th className="border-r-2 border-b-2 border-white border-dashed py-3">
                    <th className=" block w-full py-2 border-1  border-b-2 border-white border-dashed">
                      ربح (90 minutes)
                    </th>
                    <tr>
                    ربح النادي
                    </tr>
                  </th>
                  <th className="border-r-2 border-b-2 border-white border-dashed py-3">
                    <th className=" block w-full py-2 border-1  border-b-2 border-white border-dashed">
                      ربح (120 minutes)
                    </th>
                    <tr>
                    ربح النادي
                    </tr>
                  </th>
                  <th className="border-r-2 border-b-2 border-white border-dashed py-3">
                    <th className=" block w-full py-2 border-1  border-b-2 border-white border-dashed">
                      الربح السنوي
                    </th>
                    <tr>
                    ربح النادي
                    </tr>
                  </th>
                  <th className="border-r-2 border-b-2 border-white border-dashed py-3">
                    <th className=" block w-full py-2 border-1  border-b-2 border-white border-dashed">
                      الربح الشهري
                    </th>
                    <tr>
                    ربح النادي
                    </tr>
                  </th>
                  <th className="border-r-2 border-b-2 border-white border-dashed py-3">
                    <th className=" block w-full py-2 border-1  border-b-2 border-white border-dashed">
                      الربح الاسبوعي
                    </th>
                    <tr>
                    ربح النادي
                    </tr>
                  </th>
                  <th className="border-r-2 border-b-2 border-white border-dashed py-3">
                    <th className=" block w-full py-2 border-1  border-b-2 border-white border-dashed">
                      الربح اليومي
                    </th>
                    <tr>
                    ربح النادي
                    </tr>
                  </th>

                  <th className="border-r-2 border-b-2 border-white border-dashed py-3">
                    المدينه
                  </th>
                  <th className="border-r-2 border-b-2 border-white border-dashed py-3">
                    عدد المشتركين
                  </th>
                  <th className="border-r-2 border-b-2 border-white border-dashed py-3">
                    اسم النادي
                  </th>
                </tr>
              </thead>
              <tbody className="bg-neutral-700 mx-5 h-3/4 overflow-auto rounded-b-lg">
                {data ? (
                  data.map((club) => {
                    return (
                      <tr key={club.id}>
                        <td className="border-r-2 border-b-2 border-white border-dashed py-3">
                          <tr className=" flex items-center justify-between">
                            <td className="border-r-2 pl-3 flex-1 border-white border-dashed">
                              {!isNaN(club.minutes30) ? club.minutes30 : 0}
                            </td>
                            <td className=" pl-3 flex-1 border-white border-dashed">
                              {!isNaN(club.appGyms30Minutes)
                                ? club.appGyms30Minutes
                                : 0}
                            </td>
                          </tr>
                        </td>
                        <td className="border-r-2 border-b-2 border-white border-dashed py-3">
                          <tr className=" flex items-center justify-between">
                          {!isNaN(club.minutes60) ? club.minutes60 : 0}
                          </tr>
                        </td>
                        <td className="border-r-2 border-b-2 border-white border-dashed py-3">
                          <tr className=" flex items-center justify-between">
                          {!isNaN(club.minutes90) ? club.minutes90 : 0}
                          </tr>
                        </td>
                        <td className="border-r-2 border-b-2 border-white border-dashed py-3">
                          <tr className=" flex items-center justify-between">
                          {!isNaN(club.minutes120) ? club.minutes120 : 0}
                          </tr>
                        </td>
                        <td className="border-r-2 border-b-2 border-white border-dashed py-3">
                          <tr className=" flex items-center justify-between">
                          {!isNaN(club.year) ? club.year : 0}
                          </tr>
                        </td>
                        <td className="border-r-2 border-b-2 border-white border-dashed py-3">
                          <tr className=" flex items-center justify-between">
                          {!isNaN(club.month) ? club.month : 0}
                          </tr>
                        </td>
                        <td className="border-r-2 border-b-2 border-white border-dashed py-3">
                          <tr className=" flex items-center justify-between">
                          {!isNaN(club.week) ? club.week : 0}
                          </tr>
                        </td>
                        <td className="border-r-2 border-b-2 border-white border-dashed py-3">
                          <tr className=" flex items-center justify-between">
                          {!isNaN(club.day) ? club.day : 0}
                          </tr>
                        </td>

                        <td className="border-r-2 border-b-2 border-white border-dashed py-3">
                          {club.club_city || "لا يوجد"}
                        </td>
                        <td className="border-r-2 border-b-2 border-white border-dashed py-3">
                          {club.club_players }
                        </td>
                        <td className="border-r-2 border-b-2 border-white border-dashed py-3">
                          {club.club_name || "غير محدد"}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan="6"
                      style={{ height: "100%", width: "100%" }}
                      className="flex justify-center items-center"
                    >
                      <CircularProgress sx={{ color: "white" }} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <ClubSidebar />
    </div>
  );
};

export default ClubClupsReports;

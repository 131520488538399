import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BsPaypal } from "react-icons/bs";
import { SiVisa, SiMastercard } from "react-icons/si";
import { useDispatch, useSelector } from "react-redux";
import { GetClubHandler } from "../apis/user/GetClub";
import { MakeSubsHandler, MakeSubsHyper } from "./../apis/user/SubSelection";
import Cookies from "universal-cookie";
import { CircularProgress } from "@mui/material";
import axios from "axios";
// import { GetClubHandler } from "./../apis/user/GetClub";

import { createAsyncThunk } from "@reduxjs/toolkit";
const Pay = () => {
  const { id } = useParams();
  const [sub, setSub] = useState();
  const [price, setPrice] = useState();
  const [subId, setSubId] = useState(localStorage.getItem("sub_id") || "");
  const state = useSelector((state) => state.MakeSub);
  const dispatch = useDispatch();
  const [club, setClub] = useState();
  const [discountValueCode, setDiscountValueCode] = useState("");
  const [isDiscount, setIsDiscountCode] = useState("");
  const cookies = new Cookies();
  const user = cookies.get("user");
  const [discountCompleted, setDiscountCompleted] = useState(false);
  // console.log(user._id);
  // console.log(subId);
  const [isHyperPay, setHyperPay] = useState(false);
  const [checkoutId, setId] = useState(null);
  var wpwlOptions = { style: "card" };
  const handlePaypal = (id) => {
    dispatch(MakeSubsHandler({ id })).then((res) => {
      if (res.payload.data) {
        cookies.set("payment", true, { path: "/" });
        cookies.set("subId", id, { path: "/" });
        window.open(res.payload.data.approvalUrl, "_blank");
      }
    });
  };
  useEffect(() => {
    dispatch(GetClubHandler({ id })).then((res) => {
      if (res.payload.data) {
        setClub(res.payload.data.club);
      }
    });
  }, [id]);
  console.log(club);
  const handleHyperPay = async (brand) => {
    try {
      const response = await axios.post(
        // `http://localhost:8000/user/pay-visa`,
        "https://gymbackend-r5nw.onrender.com/user/pay-visa",
        { price: price, brand: brand },
        { headers: { authorization: `Bearer ${cookies.get("_auth_token")}` } }
      );
      console.log(response.data);
      setId(response.data.id);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (checkoutId) {
      const script = document.createElement("script");
      script.src = `https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
      script.async = true;

      document.head.appendChild(script);

      setHyperPay(true);
    }
  }, [checkoutId]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paymentId = searchParams.get("id");

  const navigate = useNavigate();
  console.log(user._id);
  console.log(subId);
  useEffect(() => {
    if (paymentId) {
      (async () => {
        try {
          const response = await axios.post(
            // `https://gymbackend-r5nw.onrender.com/user/check-pay/${paymentId}/${id}`,
            `https://gymbackend-r5nw.onrender.com/user/check-pay/${paymentId}/${id}`,
            { id: user._id, userSubId: subId },
            {
              headers: {
                authorization: `Bearer ${cookies.get("_auth_token")}`,
                "Content-Type": "application/json",
              },
            }
          );
          cookies.set("payment", true, { path: "/" });

          const status = response.data.status;
          console.log(response);
          console.log(status);

          // window.open(`https://appgyms.com/${status}`, "_blank");
          navigate(`/${status}`);
        } catch (err) {
          cookies.set("payment", true, { path: "/" });
          console.log(err);
          navigate(`/${err.response.data.status}`);
        }
      })();
    }
  }, [paymentId]);

  useEffect(() => {
    dispatch(GetClubHandler({ id })).then((res) => {
      if (res.payload.data) {
        setSub(res.payload.data.subscriptions);
      }
    });
  }, [id]);
  return (
    <div className="w-full flex sm:flex-row flex-col items-center justify-around bg-stone-100 rounded-3xl sm:h-screen h-full sm:p-0 p-5">
      <div
        className="flex flex-col flex-1 rounded-3xl h-fit ml-2 pt-5 w-[60%]"
        style={{
          backgroundImage: "url('/assets/main-img-blured.jpg",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="flex justify-center items-center ">
          <div className="flex w-full justify-evenly items-center">
            <img src="/assets/club_gym.png" alt="gym_img" className="w-1/4" />
            <span className="md:text-5xl text-xl text-white">
              اختر باقه الاشتراك
            </span>
          </div>
        </div>
        <div className="flex md:gap-x-5 gap-x-1 justify-center items-center w-full overflow-x-scroll sm:px-10 border-b-2 border-gray-400 py-10 px-2 ">
          {sub
            ? sub.map((sub) => (
                <div
                  key={sub._id}
                  onClick={() => {
                    setPrice(sub.price);
                    localStorage.setItem("sub_id", sub._id);
                    setSubId(sub._id);
                  }}
                  className="w-1/3 rounded-lg flex flex-col items-center justify-center px-2 py-1 sm:p-5 hover:cursor-pointer transition-all border-2 border-black hover:border-amber-300 active:border-amber-500 focus:border-amber-500"
                  style={{
                    backgroundImage: "url('/assets/main-img-blured.jpg')",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                >
                  <span className="md:text-2xl text-lg text-white ">
                    {sub.type}
                  </span>
                  <span className="md:text-2xl text-lg text-white  text-center">
                    SA{sub.price}
                  </span>
                </div>
              ))
            : ""}
        </div>

        <div className="w-full justify-around items-center flex py-10">
          {price ? (
            <div className=" flex flex-col ">
              <div>
                {club.discounts && club.discounts.length > 0 && (
                  <div>
                    {!discountCompleted && (
                      <div className="flex my-7 flex-row-reverse items-center justify-center">
                        <button
                          onClick={() => {
                            const matchedDiscount = club.discounts.find(
                              (discount) =>
                                discount.discountCode ===
                                discountValueCode.trim()
                            );

                            if (matchedDiscount) {
                              setDiscountCompleted(true);
                              let decimalPercentage =
                                matchedDiscount.discountQuantity / 100;
                              let reduction = price * decimalPercentage;
                              let newPrice = price - reduction;
                              setPrice(newPrice);
                              setIsDiscountCode(`تم خصم ${reduction} بنجاح`);
                            } else {
                              setIsDiscountCode("كود الخصم غير صحيح");
                            }
                          }}
                          className="text-sm bg-red-900 bg-opacity-90 p-2 rounded-tr-md rounded-br-md text-white font-medium"
                        >
                          تطبيق الخصم
                        </button>
                        <input
                          className="text-sm bg-yellow-300 placeholder:text-black bg-opacity-50 p-2 rounded-tl-md rounded-bl-md text-black outline-none font-medium"
                          type="text"
                          placeholder="ادخل كود الخصم"
                          value={discountValueCode}
                          onChange={(e) => setDiscountValueCode(e.target.value)}
                        />
                      </div>
                    )}
                    {isDiscount && (
                      <span className="text-white text-xl font-bold text-center block p-4 ">
                        {isDiscount}
                      </span>
                    )}
                  </div>
                )}
              </div>
              <span className="md:text-3xl text-xl text-white">
                اجمالي مبلغ الدفع : <span>{price}</span>
              </span>
            </div>
          ) : (
            <span className="md:text-3xl text-xl text-white">
              برجاء اختيار باقه الاشتراك{" "}
            </span>
          )}
        </div>
        <button
          className="opacity-50 text-white md:text-3xl text-xl py-2 mt-5 hover:opacity-100 transition-all"
          style={{ backgroundColor: "#ffffff47" }}
          onClick={() => (window.location.pathname = "/")}
        >
          الغاء عمليه الدفع
        </button>
      </div>
      {!isHyperPay && (
        <div className="w-full flex-1 flex justify-center items-center flex-col gap-y-10 my-5">
          {/* <button
            style={{ backgroundColor: "#fdc43a" }}
            className="text-2xl  flex items-center gap-x-3 justify-around py-3 px-5 rounded-md text-white hover  transition-all hover:scale-105"
            onClick={() => (subId ? handlePaypal(subId) : "")}
          >
            {state.loading ? (
              <CircularProgress size={30} style={{ color: "white" }} />
            ) : (
              <BsPaypal style={{ color: "#009cd3" }} />
            )}
            <span>الدفع عن طريق </span>
          </button> */}

          <h3 className=" font-bold text-xl text-center">الدفع عن طريق</h3>
          <div className=" flex items-center gap-4 justify-center">
            <button onClick={() => handleHyperPay("visa")}>
              <img src="/assets/visa1.png" alt="visa" className=" w-14 h-13 " />
            </button>
            <button onClick={() => handleHyperPay("mastercard")}>
              <img
                src="/assets/master-card.png"
                alt="visa"
                className=" w-14 h-13 "
              />
            </button>
            <button onClick={() => handleHyperPay("mada")}>
              <img src="/assets/mada.png" alt="visa" className=" w-14 h-13 " />
            </button>
          </div>

          {/* <button
            style={{ backgroundColor: "red" }}
            className="text-2xl  flex items-center gap-x-3    justify-around py-3 px-5 rounded-md text-white hover  transition-all hover:scale-105"
          >
            <SiMastercard style={{ color: "#eaad00" }} className="text-3xl" />
            <span>الدفع عن طريق </span>
          </button> */}
        </div>
      )}
      {isHyperPay && (
        <div className="w-full flex-1 flex justify-center items-center flex-col gap-y-10 my-5">
          <form
            action={`https://appgyms.com/pay/${id}`}
            className="paymentWidgets"
            data-brands="AMEX MADA MASTER VISA"
          ></form>
        </div>
      )}
    </div>
  );
};

export default Pay;

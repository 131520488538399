import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../../components/AdminSidebar";
import Cookies from "universal-cookie";
import { CircularProgress } from "@mui/material";
function ClubsBankAccounts() {
  const cookies = new Cookies();
  const [clubData, setClubData] = useState([]);
  async function fetchData() {
    console.log(cookies.get("_auth_token"));

    const api = "https://gymbackend-r5nw.onrender.com/admin/club_bank_account";
    const token = cookies.get("_auth_token"); // Assuming you're using some library for cookies
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const res = await axios.get(api, config);

      setClubData(res.data.data);
    } catch (error) {
      console.error("Error sending bank account:", error);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  console.log(clubData);

  if (!clubData) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      <div className="flex-1">
        <span className="text-2xl text-center block bg-neutral-700 px-5 py-3 text-white w-full">
          الحسابات البنكية لجميع الانديه
        </span>

        <div class="flex items-center justify-center w-full mt-20">
          <div class="overflow-x-scroll w-[340px] sm:w-[90%]  md:w-[80%] md:overflow-hidden ">
            <table class="min-w-full bg-white">
              <thead class="ltr:text-left rtl:text-right bg-gray-200">
                <tr>
                  <th class="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    رقم الحساب
                  </th>
                  <th class="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    اسم صاحب الحساب
                  </th>
                  <th class="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    اسم البنك
                  </th>
                  <th class="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    هاتف المستلم
                  </th>
                  <th class="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    اسم المستلم
                  </th>
                  <th class="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    اسم النادي
                  </th>
                </tr>
              </thead>

              <tbody class="divide-y divide-gray-200">
                {clubData.map((club) => (
                  <tr class="bg-gray-100" key={club._id}>
                    <td class="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                      {club.bankAccountNumber}
                    </td>
                    <td class="whitespace-nowrap px-4 py-2 text-gray-700">
                      {club.bankAccountName}
                    </td>
                    <td class="whitespace-nowrap px-4 py-2 text-gray-700">
                      {club.bankName}
                    </td>
                    <td class="whitespace-nowrap px-4 py-2 text-gray-700">
                      {club.phone}
                    </td>
                    <td class="whitespace-nowrap px-4 py-2 text-gray-700">
                      {club.name}
                    </td>
                    <td class="whitespace-nowrap px-4 py-2 text-gray-700">
                      {club.clubName}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Sidebar />
    </div>
  );
}

export default ClubsBankAccounts;

import React, { useState, useEffect } from "react";
import { Box, Button, Modal, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import Cookies from "universal-cookie";
import SubClubCard from "../../components/club/SubClubCard";
import { LoginClubHandler } from "../../apis/clubs/subclubLogin";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "7px",
  boxShadow: 24,
  p: 4,
};

function ClubManger() {
  const cookies = new Cookies();
  const [open, setOpen] = useState(false);
  const [clubId, setClubId] = useState();
  const [email, setEmail] = useState("");
  const [resOpen, setResOpen] = useState();
  const [clubMemberCode, setClubMemberCode] = useState("");
  const [subClubs, setSubClubs] = useState([]);
  const dispatch = useDispatch();
  const state = useSelector((state) => state.loginClubSlice);
  const handleLogin = () => {
    dispatch(
      LoginClubHandler({
        email: email.trim() + "@gmail.com",
        id: clubId,
      })
    );
  };

  const handleStatus = () => {
    console.log(state);
    if (state.status === 200) {
      cookies.set("_auth_token", state.data.token, { path: "/" });
      cookies.set("_auth_role", "9910811798", { path: "/" });
      cookies.set("_auth_club", state.data.user.club, { path: "/" });
      cookies.set("_auth_name", state.data.user.username, { path: "/" });
      cookies.set("user", state.data.user, { path: "/" });
      window.location.pathname = "/club/edit";
    }
    if (state.status === 404) {
      toast.error("معذره يوجد خطا ");
    }
  };

  useEffect(() => {
    handleStatus();
  }, [state.status]);
  useEffect(() => {
    const getClubs = async () => {
      const api = "https://gymbackend-r5nw.onrender.com/club/clubs-manger";
      const response = await axios.get(
        api,

        {
          headers: { authorization: `Bearer ${cookies.get("_auth_token")}` },
        }
      );
      if ((response.status = 200)) {
        setSubClubs(response.data.clubs);
      }
      console.log(response);
    };
    getClubs();
  }, []);

  const handlClubCode = async () => {
    if (clubMemberCode === "") {
      toast.error("ادخل كود العضو");
      return;
    }
    const api = "https://gymbackend-r5nw.onrender.com/auth/club-member";
    try {
      const response = await axios.post(
        api,
        {
          clubMemberCode: clubMemberCode,
        }

        // {
        //   headers: { authorization: `Bearer ${cookies.get("_auth_token")}` },
        // }
      );
      if ((response.status = 200)) {
        console.log(response.data);

        cookies.set("_auth_token", response.data.token, { path: "/" });
        cookies.set("_auth_role", "9910811798", { path: "/" });
        cookies.set("_auth_club", response.data.user.club, { path: "/" });
        cookies.set("_auth_name", response.data.user.username, { path: "/" });
        cookies.set("user", state.data.user, { path: "/" });

        window.location.pathname = "/club/edit";
      } else {
        toast.error("  يوجد خطا");
      }
    } catch {
      toast.error("  يوجد خطا");
    }
  };

  const handleOpen = (clubId) => {
    setClubId(clubId);
    setOpen(true);
  };
  return (
    <>
      <div className="min-h-[80vh]   bg-teal-600 ">
        <div className="flex  items-center  justify-between ">
          <div className="flex items-end justify-end">
            <h2 className=" text-right p-3  m-5 text-xl  sm:2xl font-semibold text-white bg-blue-500 rounded-md ">
              جميع الانديه الفرعيه
            </h2>
          </div>
          <div className="flex items-start justify-start">
            <h2
              onClick={() => {
                setResOpen(true);
              }}
              className=" text-right cursor-pointer p-3  m-5 text-xl  sm:2xl font-semibold text-white bg-black rounded-md "
            >
              {" "}
              المسؤول{" "}
            </h2>
          </div>
        </div>
        {subClubs.length < 0 ? (
          <CircularProgress color="primary" sx={{ width: "40px" }} />
        ) : (
          <div className=" flex items-center justify-center gap-10 flex-wrap w-[95vw] mx-auto my-7 ">
            {subClubs &&
              subClubs.length > 0 &&
              subClubs.map((club) => {
                return (
                  <SubClubCard
                    onclickBtn={handleOpen}
                    key={club._id}
                    club={club}
                  />
                );
              })}
          </div>
        )}
      </div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <TextField
              id="outlined-basic"
              label="Member Code"
              variant="outlined"
              required="required"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              sx={{ width: "100%", mb: "10px" }}
            />

            <div className=" flex items-end  w-full my-3 mx-7 gap-5 ">
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                variant="outlined"
                color="error"
              >
                close
              </Button>
              <Button
                onClick={() => {
                  handleLogin();
                }}
                handleAddRepresentative
                variant="contained"
                color="success"
              >
                {false ? (
                  <CircularProgress color="secondary" sx={{ width: "14px" }} />
                ) : (
                  "Go DashBoard"
                )}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={resOpen}
        onClose={() => {
          setResOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <TextField
              id="outlined-basic"
              label="Member Code"
              variant="outlined"
              required="required"
              onChange={(e) => {
                setClubMemberCode(e.target.value);
              }}
              sx={{ width: "100%", mb: "10px" }}
            />

            <div className=" flex items-end  w-full my-3 mx-7 gap-5 ">
              <Button
                onClick={() => {
                  setResOpen(false);
                }}
                variant="outlined"
                color="error"
              >
                close
              </Button>
              <Button
                onClick={() => {
                  handlClubCode();
                }}
                handleAddRepresentative
                variant="contained"
                color="success"
              >
                {false ? (
                  <CircularProgress color="secondary" sx={{ width: "14px" }} />
                ) : (
                  "Go DashBoard"
                )}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default ClubManger;

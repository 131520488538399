import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";

function YogaCard({ weekDays, updateYogaCardData, club }) {
  // Define the yoga types inside the component
  const yogaTypes = [
    "Hatha Yoga",
    "Vinyasa Yoga",
    "Ashtanga Yoga",
    "Bikram Yoga",
  ];
  console.log("from yogaCard");
  console.log(club.yogaSessions);
  const [yogaAnotherDate, setYogaAnotherDate] = useState({});
  const [yogaTypeData, setYogaTypeData] = useState([]);

  const [openDialogs, setOpenDialogs] = useState({});

  const [openFirstDialog, setOpenFirstDialog] = useState(false);
  const [openSecondDialog, setOpenSecondDialog] = useState(false);

  const handleFirstDialogClose = () => {
    setOpenFirstDialog(false);
  };

  const handleFirstDialogYes = () => {
    setOpenFirstDialog(false);
    setOpenSecondDialog(true);
  };

  const handleSecondDialogClose = () => {
    setOpenSecondDialog(false);
  };
  const handleCheckboxChange = (day, type, value) => {
    // console.log(day, type, value);

    console.log(day, type, value);
    if (value === false) {
      const existingEntryIndex = yogaTypeData.findIndex(
        (entry) => entry.day === day && entry.type === type
      );
      console.log(existingEntryIndex);
      if (existingEntryIndex !== -1) {
        const updatedData = yogaTypeData.filter(
          (e, idx) => idx !== existingEntryIndex
        );
        setYogaTypeData(updatedData);
      }
    } else {
      setOpenDialogs((prevState) => ({
        ...prevState,
        [type]: true,
        day: day,
      }));
    }
  };

  const handleCloseDialog = (type) => {
    setOpenDialogs((prevState) => ({
      ...prevState,
      [type]: false,
    }));
  };

  useEffect(() => {
    setYogaTypeData(club);
  }, [club]);

  const handleSaveTimeRange = (day, type, from, to, price) => {
    let updatedData;

    let formattedFrom = "";
    if (from) {
      const fromTime = new Date(from);
      const fromHours = String(fromTime.getHours()).padStart(2, "0");
      const fromMinutes = String(fromTime.getMinutes()).padStart(2, "0");
      formattedFrom = `${fromHours}:${fromMinutes}`;
    }

    let formattedTo = "";
    if (to) {
      const toTime = new Date(to);
      const toHours = String(toTime.getHours()).padStart(2, "0");
      const toMinutes = String(toTime.getMinutes()).padStart(2, "0");
      formattedTo = `${toHours}:${toMinutes}`;
    }

    const existingEntryIndex = yogaTypeData.findIndex(
      (entry) => entry.day === day && entry.type === type
    );

    if (existingEntryIndex !== -1) {
      // If the entry exists, update its data
      updatedData = [...yogaTypeData];
      if (from) {
        updatedData[existingEntryIndex].from = formattedFrom;
      }
      if (to) {
        updatedData[existingEntryIndex].to = formattedTo;
      }
      if (price) {
        updatedData[existingEntryIndex].price = price;
      }
    } else {
      // If the entry doesn't exist, create a new one
      updatedData = [
        ...yogaTypeData,
        {
          day: day,
          type: type,
          from: formattedFrom,
          to: formattedTo,
          price: price,
        },
      ];
    }

    setYogaTypeData(updatedData);
  };

  const handleSaveTimeRangeAgin = (day, type) => {
    let updatedData = [];

    // const from = yogaAnotherDate.from;
    // const to = yogaAnotherDate.to;
    // const price = yogaAnotherDate.price;
    // let formattedFrom = "";
    // if (from) {
    //   const fromTime = new Date(from);
    //   const fromHours = String(fromTime.getHours()).padStart(2, "0");
    //   const fromMinutes = String(fromTime.getMinutes()).padStart(2, "0");
    //   formattedFrom = `${fromHours}:${fromMinutes}`;
    // }

    // let formattedTo = "";
    // if (to) {
    //   const toTime = new Date(to);
    //   const toHours = String(toTime.getHours()).padStart(2, "0");
    //   const toMinutes = String(toTime.getMinutes()).padStart(2, "0");
    //   formattedTo = `${toHours}:${toMinutes}`;
    // }

    // // if (formattedFrom && formattedTo && price && day && type) {
    // //   updatedData = [
    // //     ...yogaTypeData,
    // //     {
    // //       day: day,
    // //       type: type,
    // //       from: formattedFrom,
    // //       to: formattedTo,
    // //       price: price,
    // //     },
    // //   ];

    // //   setYogaTypeData(updatedData);
    // // }
    // // setOpenSecondDialog(false);

    // console.log(formattedFrom, formattedTo, price, day, type); 

    setOpenSecondDialog(false);
  };

  useEffect(() => {
    // This effect will run whenever yogaCardData changes

    // Pass updated data to parent component if needed
    updateYogaCardData(yogaTypeData);
  }, [yogaTypeData, updateYogaCardData]);

  console.log(yogaTypeData);
  return (
    <div className="max-w-full overflow-x-auto">
      <p className="text-right text-3xl mb-2">Yoga Schedule:</p>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Day</TableCell>
            {yogaTypes.map((type, idx) => (
              <TableCell key={idx}>{type}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {weekDays.map((day, dayIndex) => (
            <TableRow
              key={dayIndex}
              className={dayIndex % 2 === 0 ? "bg-gray-100" : ""}
            >
              <TableCell>{day}</TableCell>
              {yogaTypes.map((type, typeIndex) => {
                const entry = yogaTypeData.find(
                  (item) => item.day === day && item.type === type
                );
                let bool = false;
                if (entry) {
                  bool = entry.day === day;
                }

                return (
                  <TableCell key={typeIndex}>
                    <Checkbox
                      checked={bool}
                      onChange={(e) =>
                        handleCheckboxChange(day, type, e.target.checked)
                      }
                    />
                    <Dialog
                      open={openSecondDialog}
                      onClose={handleSecondDialogClose}
                    
                        sx={{
                          background: "#00000",
                          opacity: "0.1", // Example: semi-transparent white
                        }}
                      
                    >
                      <DialogContent
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            label="From"
                            value={null}
                            onChange={(newValue) => {
                              setYogaAnotherDate((prevState) => ({
                                ...prevState,
                                from: newValue,
                              }));
                            }}
                            renderInput={(props) => <TextField {...props} />}
                          />
                          <div className="mx-4"></div>
                          <TimePicker
                            label="To"
                            value={null}
                            onChange={(newValue) =>
                              setYogaAnotherDate((prevState) => ({
                                ...prevState,
                                to: newValue,
                              }))
                            }
                            renderInput={(props) => <TextField {...props} />}
                          />
                        </LocalizationProvider>

                        <TextField
                          required
                          id="outlined-required"
                          label="Price"
                          type="number"
                          value={yogaAnotherDate.price || "0"}
                          onChange={(e) =>
                            setYogaAnotherDate((prevState) => ({
                              ...prevState,
                              price: e.target.value,
                            }))
                          }
                          sx={{
                            marginLeft: "10px",
                          }}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => handleSecondDialogClose}>
                          Cancel
                        </Button>
                        <Button
                          onClick={() => { 
                            console.log(openDialogs)
                            handleSaveTimeRangeAgin(openDialogs.day);
                          }}
                          color="primary"
                        >
                          Save
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Dialog
                      BackdropProps={{
                        style: {
                          backgroundColor: "rgba(0, 0, 0, 0.4)",
                          opacity: "0.4", // Example: semi-transparent white
                        },
                      }}
                      open={openDialogs[type]}
                      onClose={() => handleCloseDialog(type)}
                    >
                      <DialogContent
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            label="From"
                            value={null}
                            onChange={(newValue) => {
                              handleSaveTimeRange(
                                openDialogs.day,
                                type,
                                newValue,
                                null
                              );
                            }}
                            renderInput={(props) => <TextField {...props} />}
                          />
                          <div className="mx-4"></div>
                          <TimePicker
                            label="To"
                            value={null}
                            onChange={(newValue) => {
                              handleSaveTimeRange(
                                openDialogs.day,
                                type,
                                null,
                                newValue
                              );
                            }}
                            renderInput={(props) => <TextField {...props} />}
                          />
                        </LocalizationProvider>
                        <TextField
                          onChange={(newValue) =>
                            handleSaveTimeRange(
                              openDialogs.day,
                              type,
                              null,
                              null,
                              newValue.target.value
                            )
                          }
                          required
                          id="outlined-required"
                          label="Price"
                          type="number"
                          sx={{
                            marginLeft: "10px",
                          }}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => handleCloseDialog(type)}>
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            handleCloseDialog(type);
                            setOpenFirstDialog(true);
                          }}
                          color="primary"
                        >
                          Save
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <>
        <Dialog open={openFirstDialog} onClose={handleFirstDialogClose}>
          <DialogContent>
            <p className="text-center font-semibold text-2xl p-7  capitalize text-slate-700">
              هل تريد اضافه ميعاد اخر لنفس اليوغا
            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleFirstDialogClose}>No</Button>
            <Button onClick={handleFirstDialogYes} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </div>
  );
}

export default YogaCard;

import React, { useState } from "react";
import axios from "axios";
import Sidebar from "../../components/AdminSidebar";
import TextField from "@mui/material/TextField";
import { Button, CircularProgress } from "@mui/material";
import Cookies from "universal-cookie";

const Addcoupon = () => {
  const cookies = new Cookies();

  const [discountCode, setDiscountCode] = useState("");
  const [discountQuantity, setDiscountQuantity] = useState("");
  const [loading, setLoading] = useState(false);

  const handleAddCoupon = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://gymbackend-r5nw.onrender.com/admin/add-coupon",
        {
          discountCode,
          discountQuantity,
        },
        {
          headers: {
            Authorization: `Bearer ${cookies.get("_auth_token")}`,
          },
        }
      );
      console.log(response.data);
      alert("تم اضافه كود خصم بنجاح") // Handle success response
    } catch (error) {
      console.error("Error adding coupon:", error); // Handle error
    } finally {
      setLoading(false);
      setDiscountQuantity('')
      setDiscountCode('')
    }
  };

  return (
    <div className="flex">
      <div className="flex-1 min-h-screen bg-slate-100">
        <span className="text-2xl text-center block bg-neutral-700 px-5 py-3 text-white w-full ">
          اضافه كوبون لجميع الانديه
        </span>
        <div className="mt-36 flex items-center justify-center p-5 ">
          <div
            style={{
              transition: "all .05s ease-in-out",
            }}
            className="flex bg-gray-200 bg-opacity-60  rounded-md  shadow-lg p-5 sm:w-[400px] w-[200px] items-center flex-col justify-center flex-wrap gap-7"
          >
            <TextField
              value={discountCode}
              onChange={(e) => setDiscountCode(e.target.value)}
              id="discountCode"
              label="كود الخصم"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              value={discountQuantity}
              onChange={(e) => setDiscountQuantity(e.target.value)}
              id="discountQuantity"
              label="قيمه الخصم"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />

            {loading ? (
              <CircularProgress color="success" />
            ) : (
              <Button
                onClick={handleAddCoupon}
                sx={{
                  padding: "7px",
                  width: "100%",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
                variant="contained"
                color="success"
              >
                اضافه خصم لجميع الانديه
              </Button>
            )}
          </div>
        </div>
      </div>

      <Sidebar />
    </div>
  );
};

export default Addcoupon;

import React, { useEffect, useState } from "react";
import { IoCheckmark } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import axios from  'axios'
import { DeleteClubHandler } from "../../apis/admin/DeleteClub";  
import Cookies from "universal-cookie";
import RepresentativeSidebar from '../../components/RepresentativeSidebar'
import logo from "../../imgs/Logo.png";
const RepresentativeClub = () => {  
  const [state ,setState] =useState([])
  let repId ;
  useEffect(()=>{
    repId = cookies.get("_representative_id"); 
  },[])
async  function fetchClubs (){ 
  const api = 'https://gymbackend-r5nw.onrender.com/representative/clubs'
    try{
     
const reposnse = await axios.post(api ,{id:repId} ,    {
  headers: { authorization: `Bearer ${cookies.get("_auth_token")}` },
})
 setState([...reposnse.data.clubs])
console.log(reposnse.data.clubs)
    }catch(e){
      console.log(e)
    }
  }
  const dispatch = useDispatch();

  const cookies = new Cookies();
  const handleDelete = (id) => {
    dispatch(DeleteClubHandler({ id })).then(() => window.location.reload() );
  };

  useEffect(() => {
    fetchClubs()
  }, []);
  return (
    <div className="flex">
      <div className="flex-1 flex flex-col bg-white pb-10">
        <span className="text-2xl text-center  bg-neutral-700 px-5 py-3  text-white  w-full ">
          التحكم بالنوادي
        </span>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-3 my-3 px-5">
          {state.length > 0 &&
            state.map((club) => {
              return (
                <div
                  className="flex flex-col justify-center items-center gap-y-3 border-2 border-black h-fit"
                  key={club._id}
                >
                  <img
                    src={logo}
                    className="w-full h-[300px]"
                    alt="club Img"
                  />
                  <span className="text-xl">{club.name}</span>
                  <div className="flex justify-around w-full items-center">
                    <span
                      onClick={() => handleDelete(club._id)}
                      className="text-xl text-red-500 flex items-center cursor-pointer hover:scale-125 transition-all"
                    >
                      حذف
                      <AiOutlineClose />
                    </span>
                    <span
                      className="text-xl text-green-500 flex items-center cursor-pointer hover:scale-125 transition-all"
                      onClick={() =>
                        (window.location.pathname = `/representative/edit-club/${club._id}`)
                      }
                    >
                      تعديل
                      <IoCheckmark />
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <RepresentativeSidebar />
    </div>
  );
};

export default RepresentativeClub;

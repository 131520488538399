// slices/counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const counterSlice = createSlice({
  name: 'clupsRegion',
  initialState: {
    data :[]
  },
  reducers: {
    clupsRegionAdd: (state ,action) => { 
      const actionData = new Set([...action.payload])
      state.data =[...actionData]
    },

  },
});
export const selectClupsRegion = (state) => state.data;
export const { clupsRegionAdd } = counterSlice.actions;
export default counterSlice.reducer;

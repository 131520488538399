import React, { useEffect, useState } from "react";
import ClubSidebar from "../../components/ClubSidebar";
import { useDispatch, useSelector } from "react-redux";
import { GetClubReports } from "./../../apis/clubs/GetReports";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import axios from "axios";
import Cookies from "universal-cookie";
const ClubReport = () => {
  const cookies = new Cookies();
  const [player, setPlayer] = useState([]);
  const loading = useSelector((state) => state.GetClubReports.loading);
  const dispatch = useDispatch();
  const [earnsData, setEarnsData] = useState();

  useEffect(() => {
    dispatch(GetClubReports()).then((res) => {
      if (res.payload.data) {
        console.log("ressssssssssssssssssssssss", res);
        setPlayer(res.payload.data.all);
      }
    });
  }, []);

  useEffect(() => {
    async function fetchEarns() {
      await axios
        .get(
          `https://gymbackend-r5nw.onrender.com/club/club_earns`,

          {
            headers: { authorization: `Bearer ${cookies.get("_auth_token")}` },
          }
        )
        .then((res) => {
          setEarnsData(res.data);
          // console.log(res);
        });
    }

    fetchEarns();
  }, []);
  return (
    <div className="flex">
      <div className="flex-1 flex flex-col max-h-screen w-full ">
        <span className="text-2xl text-center bg-neutral-700 px-5 py-3  text-white  w-full ">
          تقارير النوادي
        </span>
        <div className=" w-[95%] mx-auto overflow-x-scroll">
          <table className="mt-5 mx-5 w-full ">
            <thead className="bg-neutral-700">
              <tr>
                <th className="text-sm  text-center text-white border-r-2 border-b-2 p-2 border-white border-dashed  ">
                  انتهاء الاشتراك
                </th>
                <th className="text-sm text-center text-white border-r-2 border-b-2 p-2 border-white border-dashed  ">
                  تاريخ الاشتراك
                </th>
                <th className="text-sm text-center text-white border-r-2 border-b-2 p-2 border-white border-dashed  ">
                  باقه الاشتراك
                </th>
                <th className="text-sm text-center text-white border-r-2 border-b-2 p-2 border-white border-dashed">
                  المدينه
                </th>
                <th className="text-sm text-center text-white border-r-2 border-b-2 p-2 border-white border-dashed">
                  رقم الاشتراك
                </th>
                <th className="text-sm text-center text-white  border-r-2 border-b-2 p-2 border-white border-dashed">
                  اسم الاعب
                </th>
              </tr>
            </thead>
            <tbody className="bg-neutral-700">
              {loading ? (
                <tr>
                  <td className="text-center text-white py-5">
                    <div
                      style={{ width: "100%" }}
                      className="flex justify-center items-center"
                    >
                      <CircularProgress sx={{ color: "white" }} />
                    </div>
                  </td>
                </tr>
              ) : (
                player.map((user) => (
                  <tr key={user.id}>
                    <td className="text-sm text-center text-white border-r-2 border-b-2 p-2 border-white border-dashed ">
                      {user.end_date
                        ? moment.utc(user.end_date).format("MM/DD/YYYY, HH:mm")
                        : null}
                    </td>
                    <td className="text-sm text-center text-white border-r-2 border-b-2 p-2 border-white border-dashed  ">
                      {user.start_date
                        ? moment
                            .utc(user.start_date)
                            .format("MM/DD/YYYY, HH:mm")
                        : null}
                    </td>
                    <td className="text-xl text-center text-white border-r-2 border-b-2 p-2 border-white border-dashed  ">
                      {user.subscription.name}
                    </td>
                    <td className="text-sm text-center text-white border-r-2 border-b-2 p-2 md:px-0  border-white border-dashed">
                      {user.user ? user.user.username : ""}
                    </td>
                    <td className="text-sm text-center text-white border-r-2 border-b-2 p-2 md:px-0  border-white border-dashed">
                      {user.code ?? ""}
                    </td>
                    <td className="text-sm text-center text-white border-r-2 border-b-2 p-2 md:px-0  border-white border-dashed">
                      {user.user ? user.user.username : ""}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        <div className=" w-full bg-neutral-200  mt-12 mb-10">
          <h2 className=" text-right p-9 font-semibold text-3xl  sm:text-6xl">
            الارباح
          </h2>

          <div className="relative overflow-x-auto  flex items-center justify-center m-4">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-500">
                <tr>
                  <th
                    scope="col"
                    className="p3-6  text-center text-white text-xl sm:text-3xl py-3"
                  >
                    الربح السنوي
                  </th>
                  <th
                    scope="col"
                    className="p3-6 text-center  text-white  text-xl sm:text-3xl py-3"
                  >
                    الربح الشهري
                  </th>
                  <th
                    scope="col"
                    className="px-3 text-center  text-white text-xl sm:text-3xl py-3"
                  >
                    الربح اليومي
                  </th>
                </tr>
              </thead>
              {earnsData && (
                <tbody>
                  <tr className="border-b bg-gray-800 border-gray-700">
                    <td className="px-6 py-4 text-xl text-center  font-bold whitespace-nowrap text-white">
                      $ {earnsData.yearlyEarnings ?? ""}
                    </td>

                    <td className="px-6 py-4 text-xl text-center text-gray-100 font-bold whitespace-nowrap dark:text-white">
                      $ {earnsData.monthlyEarnings ?? ""}
                    </td>
                    <td className="px-6 py-4 text-xl text-center text-gray-100 font-bold whitespace-nowrap dark:text-white">
                      $ {earnsData.dailyEarnings ?? ""}
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      <ClubSidebar />
    </div>
  );
};

export default ClubReport;

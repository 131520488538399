import React from "react";

function SubClubCard({ club, onclickBtn }) {
  return (
    <div className=" fade-in  bg-black p-2   rounded-md">
      <img className="w-[300px] rounded-md h-[250px] " src={club.logo} alt="" />
      <p className="text-white p-2 bg-teal-500 text-center text-xl">
        {club.name}
      </p>
      <button
        onClick={() =>onclickBtn(club._id)}
        className=" justify-between items-center w-full mt-7 flex gap-2 rounded border border-indigo-600 bg-indigo-600 px-8 py-3 text-white hover:bg-transparent hover:text-white focus:outline-none focus:ring active:text-indigo-500"
      >
        <span className="text-xl font-medium"> الدخول </span>

        <svg
          className=" w-12 h-12 rtl:rotate-180"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M17 8l4 4m0 0l-4 4m4-4H3"
          />
        </svg>
      </button>
    </div>
  );
}

export default SubClubCard;

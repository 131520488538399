import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
function ClupsRegionType() {
  const data = useSelector((state) => state.clupsRegion.data);
  const navigate = useNavigate();
  useEffect(() => {
    if (!data || data === null) {
      navigate("/");
    }
  }, [data]);
  console.log(data);

  return (
    <>
      {data.length > 0 ? (
        <div className=" rtl">
          <div className="w-full flex items-center text-4xl bg-slate-300 p-10  justify-center">
            النوادي القريبه من منطقتك
          </div>

          <div className=" rtl grid relative lg:grid-cols-4 md:grid-cols-3 grid-cols-1 sm:grid-cols-2 gap-10 items-center justify-items-center mt-10 mb-5 h-full overflow-hidden p-5 bg-slate-100 min-h-screen">
            {data.map((club) => {
              return (
                <div
                  className=" flex flex-col border-solid  justify-center items-center w-full   rounded-md bg-white shadow-lg "
                  key={club._id}
                >
                  <div className="h-[300px] w-full">
                    <img
                      src={club.logo}
                      alt="img"
                      className="w-full h-full p-5 "
                    />
                  </div>
                  <span className="text-2xl bg-red-900 w-full p-7 text-white flex items-center justify-center">
                    {club.name}
                    <span className=" mx-4 font-bold text-2xl text-slate-200">
                      {" "}
                      اسم النادي{" "}
                    </span>
                  </span>
                  <span className=" bg-slate-100 m-0 p-5 text-slate-800   text-2xl p-2 w-full break-words text-right">
                    {club.description}
                  </span>
                  <div className=" flex justify-between items-center p-6 w-full  text-2xl flex-row-reverse ">
                    {club.subscriptionPrice == null ? (
                      <div> لا توجد اشتركات يوميه </div>
                    ) : (
                      <>
                        <h3>الاشتراك اليومي</h3>
                        <h3 className=" text-yellow-600 p-2 bg-slate-900 rounded-md ">
                          $ {club.subscriptionPrice}
                        </h3>
                      </>
                    )}
                  </div>
                  <button
                    className="text-3xl text-white border-2 border-white bg-slate-900 w-full p-7 hover:bg-indigo-900  transition-all"
                    onClick={() =>
                      (window.location.pathname = `/clubs/${club._id}`)
                    }
                  >
                    قم بزيارته
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="flex  items-center justify-center  flex-col m-10 h-[50vh] ">
          <div className="  p-20 text-4xl  font-semibold">
            لا توجد انديه قريبه من مكانك جرب ان تعود الي الصفحه الرئسيه وتدخل
            بياناتك لتري جميع الانديه القريبه منك منك{" "}
          </div>
          <Link className=" underline text-3xl text-slate-700" to="/">
            العوده الي الخلف
          </Link>
        </div>
      )}
    </>
  );
}

export default ClupsRegionType;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import Cookies from "universal-cookie";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"; 
import { useNavigate } from "react-router-dom";
const RepresentativeDetails = () => {
  const { id } = useParams(); 
  const navigate = useNavigate()
  const [representative, setRepresentative] = useState(null);
  const cookies = new Cookies();
  useEffect(() => {
    const fetchRepresentativeData = async () => {
      try {
        const response = await axios.get(
          `https://gymbackend-r5nw.onrender.com/admin/get_representative/${id}`,
          {
            headers: { authorization: `Bearer ${cookies.get("_auth_token")}` },
          }
        );
        console.log(response);
        setRepresentative(response.data.representative);
      } catch (error) {
        console.error("Error fetching representative data:", error);
      }
    };

    fetchRepresentativeData();
  }, [id]);

  if (!representative) {
    return (
  <>
  
  <div onClick={() =>{
        navigate("/admin/add-representative")
      }} className=" w-20 flex items-center justify-center mx-auto bg-gray-200 p-4 rounded-md cursor-pointer  left-11">
        <ArrowForwardIcon />
      </div>
      <div className="flex items-center justify-center w-full h-[700px] gap-10 flex-col ">
        <h2 className="text-7xl text-slate-400 text-center ">Loading ...</h2>
        <CircularProgress color="secondary" size={100} />
      </div></>
    );
  }

  return (
    <div className="w-full min-h-[70vh]">
      <div onClick={() =>{
        window.location.pathname="/admin/add-representative"
      }} className=" w-20 flex items-center justify-center mx-auto bg-gray-200 p-4 rounded-md cursor-pointer  left-11">
        <ArrowForwardIcon />
      </div>
      <div className="  my-10 w-[70%] mx-auto flex flex-col gap-5 bg-slate-200 p-4 ">
  <div className=" flex flex-row-reverse w-full items cursor-pointer center  justify-between p-5 bg-slate-100 hover:bg-teal-100 rounded-md  ">
    <h2 className=" text-xl  md:text-3xl  font-semibold">
    :  الاسم
    </h2> 
    <h2 className="text-xl  md:text-3xl ">
      {representative.name}
    </h2>
  </div>
  <div className=" flex flex-row-reverse w-full items cursor-pointer center  justify-between p-5 bg-slate-100 hover:bg-teal-100 rounded-md  ">
    <h2 className="text-xl  md:text-3xl  font-semibold">
    :  الايميل
    </h2> 
    <h2 className="text-xl  md:text-3xl  ">
      {representative.email}
    </h2>
  </div>
  <div className=" flex flex-row-reverse w-full items cursor-pointer center  justify-between p-5 bg-slate-100 hover:bg-teal-100 rounded-md  ">
    <h2 className="text-xl  md:text-3xl font-semibold">
    :  الباسورد
    </h2> 
    <h2 className="text-xl  md:text-3xl  ">
      {representative.password}
    </h2>
  </div>
  <div className=" flex flex-row-reverse w-full items cursor-pointer center  justify-between p-5 bg-slate-100 hover:bg-teal-100 rounded-md  ">
    <h2 className="text-xl  md:text-3xl  font-semibold">
    :  العموله
    </h2> 
    <h2 className="text-xl  md:text-3xl  ">
    $  {representative.commission}
    </h2>
  </div>
      </div> 
    <div className=" text-center text-5xl font-semibold">
    الانديه التي ضافها المندوب
    </div>
    {representative.clups.length === 0 ? <div className="text-xl text-gray text-center">لا توجد الان</div> : (
      <div className="my-10 w-3/4 mx-auto flex flex-wrap items-center justify-center gap-8">

        {representative.clups.map((clup ,idx) =>{
          return(
            <div key={idx} className=" rounded-md  ">
    <div className="border-4 ">
    <div className="w-[200px] h-[150px] ">

<img src={clup.logo} alt="" className=" w-full h-full"   />
</div >
    <div className=" p-3 bg-slate-200 " > {clup.name}</div>
    </div>
          <button onClick={()=>{
            navigate(`/clubs/${clup._id}`)
          }} className="bg-red-800 hover:bg-rose-600 text-white w-full p-2 text-2xl">view</button>
        </div>
          )
        })}
      </div>
    )}
    </div>
  );
};

export default RepresentativeDetails;

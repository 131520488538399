import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from "../../components/AdminSidebar";

import { CircularProgress } from "@mui/material";
function AdminClubsOrder() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(
          "https://gymbackend-r5nw.onrender.com/user/club_order"
        );
        const data = response.data;
        if (data.success) {
          setOrders(data.data);
        } else {
          console.error("Failed to fetch orders");
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  return (
    <div className="flex min-h-full">
      <div className="flex-1">
        <span className="text-2xl text-center block flex items-center justify-center bg-neutral-700 px-5 py-3 text-white w-full">
          طلبات الانضمام
        </span>

        {loading ? (
          <div className="flex justify-center items-center h-screen">
            <CircularProgress />
          </div>
        ) : (
          <div className="overflow-x-auto w-[350px] sm:w-[800px] lg:w-[900px] md:w-[950px] my-12 mx-auto">
            <table className="min-w-full divide-y-2 divide-gray-200 bg-slate-100 rounded-lg text-sm">
              <thead className="ltr:text-left rtl:text-right">
                <tr>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Logo
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Date of Order
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Name
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Email
                  </th>
                  <th className="whitespace-nowrap px-4 py-2"></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {orders.map((order) => (
                  <tr
                    key={order._id}
                    className="hover:bg-gray-100 transition-colors duration-200"
                  >
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                      <img
                        src={order.logo || "https://via.placeholder.com/40"}
                        alt="Logo"
                        className="sm:w-32 sm:h-24  h-12 w-12 object-cover rounded-md"
                      />
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {order.createdAt
                        ? new Date(order.createdAt).toLocaleDateString()
                        : "N/A"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {order.name || "N/A"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {order.email || "N/A"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2">
                      <button
                        onClick={() => { 


                          console.log('asdvasd')
                    window.location.href = `/admin/club_order/${order._id}`;
                        }}
                        className="inline-block rounded bg-indigo-600 px-4 py-2 text-xs font-medium text-white hover:bg-indigo-700"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <Sidebar />
    </div>
  );
}

export default AdminClubsOrder;

import React, { useEffect, useState } from "react";
import Sidebar from "../../components/AdminSidebar";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Modal, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from "universal-cookie";
import { AddRepresentativeHanlder } from "../../apis/admin/AddRepresentative";
import { GetRepresentativeHanlder } from "../../apis/admin/GetRepresentatives"; 
import "../../App.css"
function AdminAddRepresentative() {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.AddRepresentative);
  const representives = useSelector((state) => state.GetRepresentative);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [getDataChanges, setChange] = useState(false);
  const [load, setLoadeAdding] = useState(false);
  const [reperData, setData] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [formDataError, setFormDataError] = useState({
    name: "",
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "7px",
    boxShadow: 24,
    p: 4,
  };
  // AddRepresentative
  useEffect(() => {
    dispatch(GetRepresentativeHanlder())
      .then((res) => setData(res.payload.data.representatives))
      .catch((res) => console.log("fallen to get all repersentives"));
  }, [getDataChanges]);

  function handleAddRepresentative() {
    if (formData.name.trim() === "") {
      setFormDataError({
        ...formDataError,
        name: "name Is missing",
      });
      ;
    }else if(formData.name.trim() !== '') {
      setFormDataError({
        ...formDataError,
        name: "",
      });
    }


    if (formData.email.trim() === "") {
      setFormDataError({
        ...formDataError,
        email: "email Is missing",
      });
      ;
    }else if(formData.email.trim() !== '') {
      setFormDataError({
        ...formDataError,
        email: "",
      });
    }
    if (formData.password.trim() === "") {
      setFormDataError({
        ...formDataError,
        password: "email Is missing",
      });
      ;
    }else if(formData.password.trim() !== '') {
      setFormDataError({
        ...formDataError,
        password: "",
      });
    } 

    if(formData.password === '' || formData.name ==='' || formData.email === ''){
      return 
    }
    
  console.log(formData)

    setLoadeAdding(true);

    dispatch(
      AddRepresentativeHanlder(formData)
    )
      .then(() => {
        setLoadeAdding(false);
        setChange(!getDataChanges);
        setOpen(!open);
        console.log("addede");
      })
      .catch(() => console.log("fail to add repersentive"));
    console.log(representives);
    setFormData({
      name:"" ,
      email:"",
      password:""
    })
  }
  async function deleteRepersentive(id) {
    setLoadeAdding(true);
    try {
      const api = `https://gymbackend-r5nw.onrender.com/admin/delete_representative/${id}`;
      const response = await axios.delete(api, {
        headers: { authorization: `Bearer ${cookies.get("_auth_token")}` },
      });
      // Send a DELETE request to the server

      // Handle success, e.g., update UI or show a success message
      console.log(response.data);
      setChange(!getDataChanges);
    } catch (error) {
      // Handle error, e.g., show an error message
      console.error(error);
    }
    setLoadeAdding(false);
  
  }
  return (
    <>
      <div className="flex min-h-screen">
        <div className=" bg-stone-100  h-full w-full flex-shrink ">
          <span className="text-2xl text-center  bg-neutral-700 px-5 py-3 block  text-white  w-full ">
            اضافه مندوب
          </span>
          <div className=" flex items=center justify-center  my-10">
            <Fab color="success" aria-label="add" onClick={handleOpen}>
              <AddIcon />
            </Fab>
          </div>
          <div class="text-gray-900 bg-gray-200">
            <div class="p-4 flex">
              <h1 class="text-3xl">Representatives</h1>
            </div>
            {reperData.length === 0 ? (
              <h2 className="  p-7 m-5 text-center text-4xl text-slate-400 ">
                {" "}
                There is No Representatives Try Add{" "}
              </h2>
            ) : (
              <div class=" py-4 flex justify-center">
                <table class="w-fullلا  text-sm  sm:text-md bg-white shadow-md rounded mb-4">
                  <tbody>
                    <tr class="border-b">
                      <th class="text-left text-sm sm:text-md md:p-3 p-1 md:px-5">Name</th>

                      <th class="text-left text-sm sm:text-md md:p-3 p-1 md:px-5">Email</th>

                      <th class="text-left text-sm sm:text-md md:p-3 p-1 md:px-5">password</th>
                      <th class="text-left text-sm sm:text-md md:p-3 p-1 md:px-5">Role</th>
                      <th></th>
                    </tr>

                    {reperData.map((reper) => (
                      <tr
                        key={reper._id}
                        class="border-b  hover:bg-orange-100 bg-gray-100 fadeIn"
                      >
                        <td class="md:p-3 p-1 text-sm sm:lg md:px-5 overflow-auto">
                          <span
                            type="text"
                            value="user.name"
                            class="bg-transparent"
                          >
                            {reper.name}
                          </span>
                        </td>
                        <td class="md:p-3 p-1 text-sm sm:lg md:px-5 overflow-auto">
                          <span
                            type="text"
                            value="user.name"
                            class="bg-transparent"
                          >
                            {reper.email}
                          </span>
                        </td>
                        <td class="md:p-3 p-1 text-sm sm:lg md:px-5 overflow-auto">
                          <span
                            type="text"
                            value="user.email"
                            class="bg-transparent"
                          >
                            {reper.password}
                          </span>
                        </td>
                        <td class="p-1 md:p-3 text-sm sm:lg md:px-5 overflow-auto">
                          <span>Representative</span>
                        </td>
                        <td class="flex-col sm:flex-row flex mb-4 ml-2  items-center  gap-3 justify-center   ">
                          <button
                            onClick={() => {
                              window.location.pathname = `/repersentative/${reper._id}`;
                            }}
                            type="button"
                            class=" text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                          >
                            Show
                          </button>
                          <button
                            onClick={() => {
                              deleteRepersentive(reper._id);
                            }}
                            type="button"
                            class="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                          >
                        Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        <Sidebar />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              sx={{ width: "100%", mb: "10px" }}
              required
              value={formData.name}
              name="name"
              onChange={handleChange}
            />
            {formDataError.name !== '' && (
              <span
                className="text-red-400  text-xl block  p-1 
          "
              >
                {formDataError.name}
              </span>
            )}
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              sx={{ width: "100%", mb: "10px" }}
              required
              value={formData.email}
              name="email"
              onChange={handleChange}
              
            />
              {formDataError.email !== '' && (
              <span
                className="text-red-400  text-xl block  p-1 
          "
              >
                {formDataError.email}
              </span>
            )}
            <TextField
              id="outlined-basic"
              label="password"
              variant="outlined"
              sx={{ width: "100%", mb: "10px" }}
              required
              value={formData.password}
              name="password"
              onChange={handleChange}
            />
              {formDataError.password !== '' && (
              <span
                className="text-red-400  text-xl block  p-1 
          "
              >
                {formDataError.password}
              </span>
            )}
            <div className=" flex items-end  w-full my-3 mx-7 gap-5 ">
              <Button
                onClick={() => {
                  setOpen(!open);
                }}
                variant="outlined"
                color="error"
              >
                close
              </Button>
              <Button
                onClick={handleAddRepresentative}
                handleAddRepresentative
                variant="contained"
                color="success"
              >
                {load ? (
                  <CircularProgress color="secondary" sx={{ width: "14px" }} />
                ) : (
                  "Add"
                )}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default AdminAddRepresentative;

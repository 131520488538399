import React, { useEffect, useRef, useState, useMemo } from "react";
import Sidebar from "../../components/AdminSidebar";
import "mapbox-gl/dist/mapbox-gl.css";
import { AiOutlineClose } from "react-icons/ai";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AddClubHandler } from "./../../apis/admin/AddClub";
import { CircularProgress } from "@mui/material";
import RepresentativeSidebar from "../../components/RepresentativeSidebar";
import Cookies from "universal-cookie";
import { TextField } from "@mui/material";
import axios from "axios";
const AddClub = () => {
  const cookies = new Cookies();
  useEffect(() => {
    const id = cookies.get("_representative_id");
    console.log(id);
  }, []);
  const [cuurentLocation, setCurrentLocation] = useState({ lat: 0, long: 0 });
  const [selection, setSelection] = useState({ lat: null, long: null });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [description, setDescription] = useState("");
  const [gender, setGender] = useState("male");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [days, setDays] = useState(2);
  const [commission, setCommission] = useState("");
  const [imgs, setImgs] = useState([]);
  const [logo, setLogo] = useState();
  const [clubImage, setClubImage] = useState([]);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const imgfiles = useRef();
  const state = useSelector((state) => state.AddClub);
  const [isChecked, setIsChecked] = useState(false);
  const [club_loc_url, setClub_loc_url] = useState("");
  const [isAddClubs, setIsAddClubs] = useState(false);
  const [isClubSub, setIsClubSub] = useState(false);
  const [clubMemberCode, setClubMembersCode] = useState("");
  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  useEffect(() => {
    if (isAddClubs === true) setIsClubSub(true);
    else setIsClubSub(false);
  }, [isAddClubs]);
  const [checkedDays, setCheckedDays] = useState([]);

  const handleBoxDays = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setCheckedDays([...checkedDays, value]);
    } else {
      setCheckedDays(checkedDays.filter((day) => day !== value));
    }
  };
  ///////////
  const [dataActivites, setDataActivites] = useState([]);
  const [checkedItemsSports, setCheckedItemsSports] = useState([]);
  const handleCheckboxChangeSports = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setCheckedItemsSports([...checkedItemsSports, value]);
    } else {
      setCheckedItemsSports(
        checkedItemsSports.filter((item) => item !== value)
      );
    }
  };
  async function GetAllActivities() {
    const api = "https://gymbackend-r5nw.onrender.com/user/activities";
    try {
      const response = await axios.get(
        api,

        {
          headers: { authorization: `Bearer ${cookies.get("_auth_token")}` },
        }
      );
      console.log(response.data);
      setDataActivites(response.data.activities);
    } catch (err) {
      setError("activity already exists");
    }
  }
  const handleClubLocUrlChange = (e) => {
    const url = e.target.value;
    setClub_loc_url(url); // Update the input value in state

    // Regular expression pattern to match latitude and longitude values in Google Maps URL
    const pattern = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
    const match = url.match(pattern);
    console.log(`lat before: ${selection.lat}`);
    // console.log(`lat before: ${typeof(selection.lat)}`);
    console.log(`long before: ${selection.long}`);
    if (match) {
      const tmp1 = parseFloat(match[1]);
      const tmp2 = parseFloat(match[2]);
      const precision = 1000000000000000; // 15 digits after decimal point
      const lat = Math.round(tmp1 * precision) / precision;
      const lon = Math.round(tmp2 * precision) / precision;
      console.log("Latitude:", typeof lat);
      console.log("Longitude:", lon);
      setCurrentLocation({ lat, long: lon }); // Update cuurentLocation state
      setSelection({ lat, long: lon }); // Update selection state
    } else {
      console.log("Invalid URL or no latitude/longitude found.");
      setCurrentLocation({ lat: 0, long: 0 }); // Reset cuurentLocation state to default
      setSelection({ lat: null, long: null }); // Reset selection state to default
    }
    console.log(`lat after: ${selection.lat}`);
    console.log(`long after: ${selection.long}`);
  };

  const handleForm = () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("description", description);
    formData.append("gender", gender);
    formData.append("lat", selection.lat);
    formData.append("long", selection.long);
    formData.append("from", from);
    formData.append("to", to);
    formData.append("clubMemberCode", clubMemberCode);

    formData.append("mapUrl", club_loc_url);
    formData.append("allDay", isChecked);
    formData.append("repreentative_id", cookies.get("_representative_id"));
    formData.append("sports", checkedItemsSports);
    formData.append("days", checkedDays);
    formData.append("isAddClubs", isAddClubs);
    {
      clubImage && clubImage.forEach((img) => formData.append("clubImg", img));
    }
    formData.append("logo", logo);
    formData.append("commission", commission);
    dispatch(AddClubHandler(formData)).then((res) => {
      console.log(res.payload);
      setError("");
      if (res.payload.status)
        if (res.payload.status === 201) {
          window.location.reload();
        } else if (res.payload.status === 409) {
          setError("يوجد نادي بهذه البيانات");
        } else if (res.status === 500) {
          setError("يوجد خطأ");
        } else if (res.payload.status === 400) {
          setError("يوجد خطأ في البيانات التي ادخلتها");
        }
    });
  };

  ////////////

  const handleLogoChange = (event) => {
    const files = Array.from(event.target.files);
    setLogo(files[0]);
  };

  const handleClubImages = (event) => {
    const files = Array.from(event.target.files);
    const updatedFiles = files.map((file, index) => ({
      key: index++,
      data: file,
    }));
    const eachFile = updatedFiles.map((file) => file.data);
    setClubImage(eachFile);
  };

  const handleImgChange = (imgs) => {
    let fileNames = [];
    for (let i = 0; i < imgs.length; i++) {
      const file = imgs[i];
      fileNames.push(file.name);
    }
    setImgs(fileNames);
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  useEffect(() => {
    // if ("geolocation" in navigator) {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       const { latitude, longitude } = position.coords;
    //       setCurrentLocation({ lat: latitude, long: longitude });
    //     },
    //     (error) => {
    //       console.error("Error getting location:", error);
    //     }
    //   );
    // } else {
    //   console.error("Geolocation is not supported by your browser");
    // }
    GetAllActivities();
  }, []);
  const handleMapClick = (e) => {
    const { latLng } = e;
    const lat = latLng.lat();
    const long = latLng.lng();
    setSelection({ lat, long });
  };
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCewVD8Afv0cy6NGoCZkQ4PZRW3OQCFfHA",
  });
  const center = useMemo(
    () => ({
      lat: cuurentLocation.lat ? cuurentLocation.lat : 24.713552,
      lng: cuurentLocation.long ? cuurentLocation.long : 46.675297,
    }),
    [cuurentLocation]
  );

  return (
    <div className="flex min:h-screen ">
      <Formik onSubmit={handleForm} initialValues={initialStates}>
        {({ values, handleChange, handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            className="flex-1 flex flex-col bg-stone-100 items-end gap-y-5 rounded-lg w-screen"
          >
            <span className="text-2xl text-center  bg-neutral-700 px-5 py-3  text-white  w-full ">
              اضافه نادي
            </span>
            <div className="flex flex-col w-3/5   px-5">
              <span className="text-2xl text-right">اضف نادي</span>
              <span className="text-md text-right text-gray-500">
                اكتب بيانات النادي حتي تتم اضافتها
              </span>
              <div className="flex justify-end gap-x-3 flex-wrap-reverse gap-y-2">
                <select
                  name="gender"
                  value={values.gender}
                  onChange={handleChange}
                  onChangeCapture={(e) => setGender(e.target.value)}
                  type="text"
                  className="border-2 text-right border-black  px-3 py-1 text-xl"
                >
                  <option value={"male"}>ذكور</option>
                  <option value={"female"}>اناث</option>
                  <option value={"both"}>مشترك</option>
                </select>
                {/* <select
                  name="days"
                  onChange={handleChange}
                  onChangeCapture={(e) => setDays(e.target.value)}
                  value={values.days}
                  type="text"
                  className="border-2 text-right border-black  px-3 py-1 text-xl"
                >
                  <option value={2}>يومين</option>
                  <option value={3}>ايام 3 </option>
                  <option value={4}>4 ايام</option>
                </select> */}
                <input
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onChangeCapture={(e) => setName(e.target.value)}
                  type="text"
                  className="border-2 text-right border-black sm:w-1/3 px-3 py-1 text-xl rounded-md"
                  placeholder="اسم النادي"
                />
              </div>
            </div>
            <div className="flex sm:flex-row flex-col-reverse sm:gap-x-5 items-center  px-5">
              <div className="flex flex-col justify-center items-end">
                <span className="text-xl text-right">
                  {" "}
                  مواعيد العمل يوم الجمعه
                </span>
                <div className="flex justify-center flex-wrap gap-5">
                  <div className="flex flex-col-reverse   sm:flex-row justify-center flex-wrap gap-5">
                    <div>
                      <input
                        name="to"
                        value={values.to}
                        onChange={handleChange}
                        onChangeCapture={(e) => setTo(e.target.value)}
                        type="time"
                      />
                      <span className="text-lg ml-1 ">الي</span>
                    </div>
                    <div>
                      <input
                        name="from"
                        value={values.from}
                        onChange={handleChange}
                        onChangeCapture={(e) => setFrom(e.target.value)}
                        type="time"
                      />
                      <span className="text-lg ml-1">من</span>
                    </div>
                  </div>
                  <div>
                    <label htmlFor="checkbox" className="flex items-center">
                      <input
                        type="checkbox"
                        name="allDay"
                        id="checkbox"
                        className="mr-2"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                      <span>24ساعة</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex flex-col my-2">
                <span className="text-xl text-right">لوجو النادي</span>
                <input
                  onChange={handleLogoChange}
                  name="logo"
                  type="file"
                  className=" text-sm w-[90%] text-white bg-neutral-700 border-2 border-black rounded-md p-2"
                />
              </div>
            </div>
            <div className="p-4 ">
              <h3 className="text-center text-2xl text-slate-800 mb-4 ">
                اوقات العمل خلال الاسبوع
              </h3>
              <div className="  flex-wrap flex gap-3 items-center justify-center">
                {weekdays.map((day, idx) => {
                  return (
                    <div
                      key={idx}
                      className=" bg-indigo-800 p-2 gap-2 flex items-center  justify-between rounded-md cursor-pointer"
                    >
                      <h3 className="text-sm text-slate-100 ">{day}</h3>
                      <input
                        id="default-checkbox"
                        type="checkbox"
                        value={day}
                        onChange={handleBoxDays}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="p-4 ">
              <h3 className="text-center text-2xl font-medium block m-4 p-4 ">
                {" "}
                الانشطه الرياضيه{" "}
              </h3>

              {dataActivites?.length === 0 ? (
                <span className=" block text-center  text-slate-700 text-xl">
                  لا يوجد اي انشطه مضافه
                </span>
              ) : (
                <div className="flex gap-7 flex-wrap items-center justify-center">
                  {dataActivites?.map((activity, idx) => {
                    return (
                      <div
                        key={idx}
                        className="bg-gray-200 p-2 sm:p-4 flex items-center gap-7 justify-between rounded-md cursor-pointer"
                      >
                        <h3 className="text-lg sm:text-2xl text-slate-800 ">
                          {activity.sportName}
                        </h3>
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          value={activity.sportName}
                          onChange={handleCheckboxChangeSports}
                          className=" w-5 h-5 sm:w-7 sm:h-7 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="flex sm:flex-row flex-col-reverse sm:gap-x-5  px-5">
              <div className="flex flex-col justify-center items-end">
                <span className="text-xl text-right">كلمه السر </span>
                <div className="flex justify-around gap-x-5">
                  <input
                    minLength={6}
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onChangeCapture={(e) => setPassword(e.target.value)}
                    type="password"
                    placeholder="(كلمه المرور (لا تقل عن 6"
                    className=" w-full px-5 py-2 border-2 border-black rounded-lg"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-xl text-right">البريد الالكتروني</span>
                <input
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onChangeCapture={(e) => setEmail(e.target.value)}
                  type="email"
                  className="  w-full px-5 py-2 border-2 border-black rounded-lg"
                />
              </div>
            </div>
            <div className="flex flex-col w-full  px-5">
              <span className="text-xl text-right">عن النادي</span>
              <span className="text-md text-right text-gray-500">
                أدخل وصف النادي
              </span>
              <textarea
                name="description"
                value={values.description}
                onChange={handleChange}
                onChangeCapture={(e) => setDescription(e.target.value)}
                className="w-full  resize-none border-2 border-black min-h-52 text-right"
              ></textarea>
            </div>
            <div className="flex flex-col w-full  px-5 items-end">
              <span className="text-xl text-right">عموله الموقع</span>
              <span className="text-md text-right text-gray-500">
                أدخل عموله الموقع من اشتراك العملاء من النادي
              </span>
              <input
                name="commission"
                value={values.commission}
                onChange={handleChange}
                onChangeCapture={(e) => setCommission(e.target.value)}
                type="number"
                placeholder=" %"
                className="w-52  resize-none border-2 border-black py-2 text-lg "
              />
            </div>{" "}
            <div className="bg-gray-200 p-2  mx-10 sm:p-4 flex items-center gap-7 justify-between rounded-md cursor-pointer">
              <h3 className="text-lg sm:text-2xl text-slate-800 ">افرع اخري</h3>
              <input
                id="default-checkbox"
                type="checkbox"
                checked={isAddClubs}
                onChange={(e) => {
                  setIsAddClubs(e.target.checked);
                }}
                className=" w-5 h-5 sm:w-7 sm:h-7 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
            </div>
            {isClubSub && (
              <div className=" my-4 mx-4">
                <TextField
                  onChange={(e) => {
                    setClubMembersCode(e.target.value);
                  }}
                  id="outlined-number"
                  label=" رمز عضويه"
                  type="text"
                  value={clubMemberCode}
                />
              </div>
            )}
            <div className="flex flex-col w-full  px-5">
              <span className="text-xl text-right">صور النادي</span>
              {/* if no Imgs*/}
              {/* <span>قم باضافه صور النادي</span> */}
              {/*if imgs*/}
              <div className="flex flex-col  gap-x-3 justify-center items-center ">
                <input
                  type="file"
                  multiple
                  className=" text-sm w/fit text-white bg-neutral-700 border-2 border-black rounded-md p-2"
                  ref={imgfiles}
                  onChange={(e) => {
                    handleImgChange(e.target.files);
                    handleClubImages(e);
                  }}
                />
                <div className="flex flex-col justify-center  items-center my-3">
                  {imgs.length ? (
                    imgs.map((name) => {
                      return (
                        <div className="flex  items-center w-full justify-evenly gap-x-5">
                          <AiOutlineClose
                            className="text-red-500"
                            onClick={() =>
                              setImgs(
                                imgs.filter((filename) => filename !== name)
                              )
                            }
                          />
                          <span className="text-lg ">{name}</span>
                        </div>
                      );
                    })
                  ) : (
                    <span className="text-xl text-red-500">
                      فم باضافه صور النادي
                    </span>
                  )}
                </div>
              </div>
              {/* add location url*/}
              <div className="flex flex-col w-full  px-5">
                <span className="text-xl text-right">رابط عنوان النادي </span>
                <span className="text-md text-right text-gray-500">
                  اختياري: يمكنك ادخال رابط عنوان النادي من خرائط جوجل او
                  التحديد من الخريطة
                </span>
                <textarea
                  name="club_loc_url"
                  value={club_loc_url}
                  onChange={handleClubLocUrlChange}
                  className="w-full  resize-none border-2 border-black min-h-52 text-right"
                ></textarea>
              </div>

              <div className="w-full justify-center items-end flex flex-col  px-5">
                <span className="text-xl">عنوان النادي</span>
                {/* Map */}

                <div id="map" className="h-screen w-full relative map">
                  {!isLoaded ? (
                    <h1>Loading...</h1>
                  ) : (
                    <GoogleMap
                      mapContainerClassName="map-container"
                      center={center}
                      zoom={10}
                      onClick={(e) => handleMapClick(e)}
                    >
                      <Marker
                        position={
                          selection.lat
                            ? {
                                lat: selection.lat,
                                lng: selection.long,
                              }
                            : {
                                lat: cuurentLocation.lat
                                  ? cuurentLocation.lat
                                  : 24.713552,
                                lng: cuurentLocation.long
                                  ? cuurentLocation.long
                                  : 46.675297,
                              }
                        }
                        icon={"/assets/placeholder_google_maps1.jpg"}
                      />
                    </GoogleMap>
                  )}
                </div>
              </div>
              <div className="flex w-full items-center justify-center ">
                <button
                  className={`${
                    selection.lat &&
                    name &&
                    email &&
                    password &&
                    description &&
              
                    gender &&
                    ((from && to) || isChecked) &&
                    commission
                      ? "  text-white bg-green-600 text-2xl my-5 px-5 py-2 w-1/4 hover:scale-125 transition-all  rounded-lg "
                      : " bg-red-500 pointer-events-none text-white text-2xl my-5 px-5 py-2 w-1/4 "
                  }" `}
                >
                  {selection.lat &&
                  name &&
                  email &&
                  password &&
                  description &&
                  gender &&
                
                  dataActivites.length > 0 &&
                  checkedDays.length > 0 &&
                  ((from && to) || isChecked) &&
                  commission ? (
                    <span>
                      {state.loading ? (
                        <CircularProgress sx={{ color: "white" }} size={30} />
                      ) : (
                        "اضافه"
                      )}
                    </span>
                  ) : (
                    <span>اكمل بيانات النادي</span>
                  )}
                </button>
              </div>
              <div className="text-center text-red-500 font-bold">{error}</div>
            </div>
          </form>
        )}
      </Formik>
      <RepresentativeSidebar />
    </div>
  );
};

const initialStates = {
  name: "",
  email: "",
  password: "",
  description: "",
  gender: "",
  lat: "",
  long: "",
  from: "",
  to: "",
  days: "",
  commission: "",
};

export default AddClub;
